import { chef, dishes } from 'cookery';
import './styles';
import partnerMapDocUrl from '../../../docs/sck.docx';

const { sectionTpl, titleSectionTpl, buttonLinkSoftTpl } = dishes;

const getAboutInfoHtml = () => {
    const imageCmp = chef.cookHandlers({ defaultHandlers: { defaultsFor: 'scrolled' } });
    return `<div class="ck-about-info-container">
                <div class="ck-about-info">
                  ${titleSectionTpl({ text: 'Чем мы занимаемся', size: 'l', color: 'black', addCls: 'ck-about-title-section' })}
                  <p> Наша деятельность сфокусирована на реализации кабельно-проводникой и электротехнической продукции, 
                  а так же оборудования необходимого для строительства объектов в отрасли промышленности и энергетики. 
                  Мы играем значительную роль в энергетическом секторе, обеспечивая необходимые компоненты для создания и обновления кабельных маршрутов. 
                  В области строительства мы обеспечиваем поставки изделий для электроснабжения объектов как гражданского, так и индустриального назначения.</p>
                  <p>В сегменте металлургии предлагаем изделия как универсального, так и узкоспециализированного применения, предназначенные для потребностей металлургической индустрии.
                  А для нефтегазовой сферы наш ассортимент включает кабельную продукцию, способную выдерживать экстремальные условия эксплуатации, 
                  в том числе для обеспечения работы насосов в нефтяных скважинах.</p>
                  ${buttonLinkSoftTpl({ text: 'Карта партнера', style: 'margin: 20px 0 0 0', link: partnerMapDocUrl })}
                  </div>
                <div id="${imageCmp.id}" class="ck-about-image">
                  <div><div></div></div>
                  <div><div></div></div>
                  <div></div>
                  <svg width="100%" height="100%">
                    <defs>
                      <clipPath id="aboutImageMask">
                        <rect width="100%" height="40"/>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
            </div>`;
}

const about = () => {
    return sectionTpl({ id: 'about', contentHtml: getAboutInfoHtml(), addCls: 'ck-about' });
}

export default about; 