import { chef, dishes } from 'cookery';
import './styles';

const { sectionTpl, titleSectionTpl } = dishes;

const getAboutInfoHtml = () => {
    const imageCmp = chef.cookHandlers({ defaultHandlers: { defaultsFor: 'scrolled' } });

    return `<div class="ck-advantages-info-container-b">
                <div class="ck-advantages-info">
                  ${titleSectionTpl({ text: 'Проверенная продукция', size: 'l',  align: 'left', color: 'black', addCls: 'ck-advantages-title-section' })}
                  <p>Долгосрочное сотрудничество с ведущими производителями кабельно-проводниковой продукции, электротехнических изделий и специализированного оборудования дало нам возможность предложить высококачественную продукцию для энергетического сектора.</p>
                  ${titleSectionTpl({ text: 'Удобная коммуникация', size: 'l', align: 'left', color: 'black', addCls: 'ck-advantages-title-section' })}
                  <p>Специально для вас мы берем на себя организацию и управление контактами с производителями, поставщиками и сервисными службами, делая процесс коммуникации более гладким и освобождая ваши время и средства для других задач.</p>
                </div>
                <div id="${imageCmp.id}" class="ck-advantages-image">
                  <div><div></div></div>
                  <div><div></div></div>
                </div>
            </div>`;
}

const advantages = () => {
    return sectionTpl({ id: 'advantages-b', contentHtml: getAboutInfoHtml(), addCls: 'ck-advantages ck-advantages-b' });
}

export default advantages; 