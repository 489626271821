import { chef } from '../../../../../index';
import { buttonIcoWhatsappTpl, buttonTpl, buttonIcoTpl } from  '../../buttons';
import { isObject, joggOnArray, getDomenFromUrl, getFormatedPhoneNumber } from '../../../../../utils';
import iconsHtml from '../../../../images/icons/html';
import './styles';

const getMainStyles = (data = {}) => {
    const { cls, addCls, style } = data;
    return chef.useCookedStyles({ cls: cls ? cls : 'ck-footer', addCls, style, joint: true });
}
const getMainMenuItems = (data = {}) => {
    const { menuItems } = data;
    let menuItemsHtml = '';
    joggOnArray(menuItems, (it) => {
        const cmp = chef.cookHandler({ 
                  type: 'click', 
                  cretaeCmpType: 'button',
                  defaultHandlers: { defaultsFor: 'button' },
                  fn: () => chef.goTo({ url: it.url })
              });
        menuItemsHtml += `<li id="${cmp.id}"><a>${it.name}</a></li>`;
    });
    return menuItemsHtml;
};
const getPolicyMenuItemHtml = (data = {}) => {
    const btnCmp = chef.cookHandler({
            type: 'click', 
            cretaeCmpType: 'button',
            defaultHandlers: { defaultsFor: 'button' },
            fn: () => chef.goTo({ url: 'privacy-policy', blankPage: true })
        });
    return `<li id="${btnCmp.id}"><a>Политика<br />конфиденциальности</a></li>`;
};
const getPartnerMapItemHtml = (data = {}) => {
    const { partnerMapDocUrl } = data;
    const btnCmp = chef.cookHandler({
            type: 'click', 
            cretaeCmpType: 'button',
            defaultHandlers: { defaultsFor: 'button' },
            fn: () => chef.goTo({ url: partnerMapDocUrl, blankPage: true })
        });
    return `<li id="${btnCmp.id}"><a>Карта партнера</a></li>`;
};
const getFeedbackButtonHtml = (data = {}) => {
    const { buttons, onClick } = data; 
    if (buttons === false) {
        return '';
    } else if (isObject(buttons) && buttons.feedback) {
        if (buttons.feedback.off) return '';
    } else {
        return buttonTpl({ text: 'Cвязаться', color:'white', handler: { type: 'click', fn: onClick }});
    }
}

const getDefaultContentHtml = (data = {}) => {
    const { logoHtml } = data;
    const menuItems = chef.get('mainMenu.items');
    const mainMenuItemsHtml = getMainMenuItems({ menuItems });
    const policyMenuItemHtml = getPolicyMenuItemHtml();
    const partnerMapItemHtml = getPartnerMapItemHtml(data)
    const logoCmp = chef.cookHandler({ type: 'click', fn: () => chef.goToHome() });
    const whatsappPhone = function () {
        const regEx = /[^\d\+]/g;
        return chef.get('contacts.phone').replace(regEx, '');
    }();
    const mainUrl = chef.get('mainUrl');
    return `<div class="ck-footer-column">
                <p class="ck-footer-column-title">Навигация</p>
                <ul class="ck-footer-column-items">
                    ${mainMenuItemsHtml}

                </ul>
            </div>
            <div class="ck-footer-column">
                <p class="ck-footer-column-title" >Прочее</p>
                <ul class="ck-footer-column-items">
                    ${policyMenuItemHtml}
                    ${partnerMapItemHtml}
                </ul>
            </div>
            <div class="ck-footer-column">
                <p class="ck-footer-column-title">Контакты</p>
                <ul class="ck-footer-column-items">
                <li><a href="mailto:${chef.get('contacts.email')}">${chef.get('contacts.email')}</a></li>
                <li>
                    <a href="tel:${chef.get('contacts.phone')}">${getFormatedPhoneNumber(chef.get('contacts.phone'))}</a> 
                    <a href="https://wa.me/${whatsappPhone}">${buttonIcoWhatsappTpl()}</a>
                </li>
                <li>${chef.get('contacts.address')}</li>
                </ul>
                ${getFeedbackButtonHtml({...data, onClick: () => chef.set('windowFeedbackShow', true)})}
            </div>
            <div class="ck-footer-column" style="min-width: 100%;">
                <div class="ck-footer-copyright">
                    ${logoHtml}                
                    <div class="copyright-info">
                      <a href="${mainUrl}">www.${getDomenFromUrl(mainUrl)}</a> ${chef.get('copyrightText')}
                    </div>
                </div>
            </div>
            `;
}

const getContentHtml = (data = {}) => {
    const { itemsHtml, bodyHtml, contentHtml } = data;
    return itemsHtml || bodyHtml || contentHtml || getDefaultContentHtml(data);
}

const footerTpl = (initData = {}) => {
    const { cmp, id } = initData;
    const mainCmp = cmp ? cmp : chef.cookCmp({ id }); 
    const mainStyles = getMainStyles(initData);
    const scrollUpBtnCmp = chef.cookHandler({ 
        type: 'click',
        fn: () => chef.scrollUp(),
        defaultHandlers: { defaultsFor: ['button', 'scrolled'] },
        createCmpType: 'button',
              
    })
    return `<div id="${mainCmp.id}" ${mainStyles}>
               <div class="ck-footer-background"></div>
               <div class="ck-footer-body">
                  ${getContentHtml(initData)}
               </div>
               ${buttonIcoTpl({ cmp: scrollUpBtnCmp, addCls: 'ck-footer-button-scroll-up', icoHtml: iconsHtml.icoUp })}
           </div>`
}
export default footerTpl;