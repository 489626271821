import { chef } from '../../../../../../index';
import classNames from '../../../../../classNames';
import './styles';

const { templates: { button: buttonClassNames }} = classNames;

const getMainStyles = (data = {}) => {
  let { cls, addCls, modCls = '', style, rounded, btnColor, txtColor } = data;
  
  if (rounded) { modCls += ' ck-button-rounded'; }
  if (btnColor == 'main') { modCls += ' ck-button-color-main'; }
  else if (btnColor == 'second') { modCls += ' ck-button-color-second'; }
  else if (btnColor == 'white') { modCls += ' ck-button-color-white'; }
  else if (btnColor == 'black') { modCls += ' ck-button-color-black'; }

  if (txtColor == 'main') { modCls += 'ck-button-text-color-main'; }
  else if (txtColor == 'second') { modCls += ' ck-button-text-color-second'; }
  else if (txtColor == 'white') { modCls += ' ck-button-text-color-white'; }
  else if (txtColor == 'black') { modCls += ' ck-button-text-color-black'; }

  return chef.useCookedStyles({ cls: cls ? cls : `${buttonClassNames.button} ${modCls}`, addCls, style, joint: true });
}
const getWrapperStyles = (data = {}) => {
  const { cls, addCls, style } = data.wrapper || {};
  return chef.useCookedStyles({ cls: cls ? cls : buttonClassNames.wrapper, addCls, style, joint: true });
}
const getIcoHtml = (data = {}) => {
  const { icoUrl, icoHtml } = data;
  return icoHtml ? icoHtml : icoUrl ? `<div style="background-image: url(${icoUrl});"></div>` : '';
}

const buttonBaseTpl = (initData = {}) => {
  const { text, info, ico } = initData;
  const btnStyles = getMainStyles(initData);   
  const wrpStyles = getWrapperStyles(initData);
  const icoHtml = getIcoHtml(initData);
  const cmp = chef.cookHandlers({ 
    ...initData, 
    defaultHandlers: { defaultsFor: 'button' } 
  });

  if (info) {
      return `<div ${wrpStyles}>
                <div id="${cmp.id}" ${btnStyles}>
                  <div class="${buttonClassNames.text}">${text || '...'}</div>
                  <div class="${buttonClassNames.ico}">${ico || ''}</div>
                  <div class="${buttonClassNames.disabler}"></div>
                </div>
                ${info ? `<div class="ck-button-info>${info}</div>` : ''}
              </div>`
  } else {
      return `<div id="${cmp.id}" ${btnStyles}>
                <div class="${buttonClassNames.text}">${text || '...'}</div>
                <div class="${buttonClassNames.ico}">${icoHtml}</div>
                <div class="${buttonClassNames.disabler}"></div>
              </div>`
  }
}

export default buttonBaseTpl;