import classNames from './classNames';
import templates from './templates';
import imagesHtml from './images/images/html';
import iconsHtml from './images/icons/html';

const theme = { 
    templates,
    imagesHtml,
    iconsHtml,
    classNames
}

export default theme;