import { chef } from '../../../../../index';
import classNames from '../../../../classNames';
import { isObject, joggOnArray  } from '../../../../../utils';
import './styles';

const { templates: { infoCard: infoCardClassNames }} = classNames;

const getMainStyles = (data = {}) => {
    const { cls, addCls, modCls, style} = data;
    return chef.useCookedStyles({ cls: cls ? cls : infoCardClassNames.infoCard, addCls, modCls, style, joint: true });
};
const getItemHtml = (data = {}) => {
    const { name, value, tooltip } = data;
    return `<div class="${infoCardClassNames.item}">
             <diV>${name}</div>
             <diV>${value}</div>
         </div>`
};
const getItemsHtml = (data = {}) => {
    const { itemsData, itemsHtml } = data;
    if (itemsHtml && isString(itemsHtml)) {
        return itemsHtml;
    } else if (isObject(itemsData) && itemsData.items) {
        let html = '';
        joggOnArray((itemsData.items || []), (it) => {
            html += getItemHtml(it);
        });
        return html;
    } else {
        return '';
    }
};

const infoCardTpl = (initData = {}) => {
    const { cmp, id, title } = initData;
    const mainCmp = chef.cookCmp({ cmp, id });
    const mainStyles = getMainStyles(initData);
    const itemsHtml = getItemsHtml(initData);
    
    return `<div id="${mainCmp.id}" ${mainStyles}>
               <div class="${infoCardClassNames.title}">${title}</div>
               <div class="${infoCardClassNames.items}">${itemsHtml}</div>
           </div>`;
}; 

export { infoCardTpl }