import labelBaseTpl from '../base';
import iconsHtml from '../../../../images/icons/html';
import './styles';

const labelAlertTpl = (data = {}) => {
    const { negative, positive, main, icoHtml, icoUrl } = data;
    const modCls = negative ? 'ck-label-alert ck-negative' : 
                   positive ? 'ck-label-alert ck-positive' : 
                   main ? 'ck-label-alert ck-main' : 'ck-label-alert';
    return labelBaseTpl({ ...data, modCls, icoHtml: icoHtml ? icoHtml : !icoUrl ? iconsHtml.icoExclame : '' });
}

export { labelAlertTpl }