import { chef, dishes, spices, recipe } from 'cookery';
import titleBgroundImg from './images/background.jpg'
import './styles';

const { iconsHtml } = recipe;

const { isObject, isString } = spices;
const { sectionTpl, labelAlertTpl } = dishes;
const getInfoLabelHtml = (data = {}) => {
    let { info } = data;
    if (info) {
        if (isObject(info)) {
            return labelAlertTpl({ ...info, main: true, icoHtml: iconsHtml.icoStar });
        } else if (isString(info)) {
            return labelAlertTpl({ text: info, main: true });
        }
    } else {
        return ''; 
    }
}

const pageTitle = (data = {}) => {
    let { title } = data;
    const infoLabelHtml = getInfoLabelHtml(data);
    return sectionTpl({
        title: '',
        contentHtml: `<div class="ck-page-title">
                        <div class="ck-page-title-text">${title ? title : chef.getPageLabel()}</div>
                        ${infoLabelHtml}
                     </div>`,
        background: {
            contentHtml: `<div class="ck-page-tittle-background">
                            <div></div>
                            <div></div>
                            <svg width="100%" height="100%">
                            <defs>
                                <clipPath id="greetingBackgroundMask">
                                <rect width="100%" height="80"/>
                                </clipPath>
                            </defs>
                            </svg>
                        </div>`
        },
        addCls: 'ck-page-title-container'
    })
}

export default pageTitle;