import { chef, dishes, recipe } from 'cookery'
import imgBackground from './images/background.jpg';
import './styles';

const { sectionTpl, sliderTpl, buttonSpecialAnimated1Tpl, canvasImgAnimatedTpl } = dishes;
const { iconsHtml } = recipe;

const mainSlider = () => {
    return sliderTpl({
                controls: true,
                slideEffect: 'slideX',
                slideTimeout: true,
                addCls: 'ck-greeting-slider',
                slides: [
                    { html: `<div class="ck-greeting">
                                <div class="ck-greeting-background">
                                    <div></div>
                                    <div></div>
                                    <svg width="100%" height="100%">
                                      <defs>
                                        <clipPath id="greetingBackgroundMask">
                                          <rect width="100%" height="80"/>
                                        </clipPath>
                                      </defs>
                                    </svg>
                                    <div></div>
                                </div>
                                <div class="ck-greeting-info-container">
                                    <div class="ck-greeting-info">
                                        <h1>Энергия движется с нами!</h1>
                                        <p>Кабельно-проводниковая и электротехническая продукця, <span>а также оборудование для решения</span> задач любой сложности.</p>
                                        ${buttonSpecialAnimated1Tpl({ 
                                            text: 'Подробнее', 
                                            handler: { type: 'click', fn: () => chef.scrollTo({ queryEl: '#about'}) },
                                            addCls: 'ck-button-greeting'
                                        })}
                                    </div>
                                    </div>
                                </div>
                            </div>` 
                    }
                ],
            });
}

const greeting = () => {
    return sectionTpl({ contentHtml: mainSlider(), addCls: 'ck-greeting-section', disableDefaultHandlers: true });
}
/**
 *  ${canvasImgAnimatedTpl({ animateType: 'wave', imgSrc: imgBackground, wrapper: { addCls: 'ck-greeting-canvas-background' } })}
 */
export default greeting;