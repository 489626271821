import { chef } from '../../../../../index';
import classNames from '../../../../classNames';
import './styles';

const { templates: { canvas: canvasClassName }} = classNames;
const getMainStyles = (data = {}) => {
    const { cls, addCls, style } = data;
    return chef.useCookedStyles({ cls: cls ? cls : canvasClassName.canvas, addCls, style, joint: true });
}
const getWrapperStyles = (data = {}) => {
    const { cls, addCls, style } = data.wrapper || {};
    return chef.useCookedStyles({ cls: cls ? cls : canvasClassName.wrapper, addCls, style, joint: true });
}

const loadImage = (imgSrc = '', onLoadCallback ) => {

}
const useAnimateWave = (canvasCmp, imgSrc) => { 
    const canvas = canvasCmp.getEl();
    const ctx = canvas.getContext('2d');
    canvas.width = 1000;
    canvas.height = 7000;

    let image = new Image();
    image.src = imgSrc;
    image.onload = function() {}
    function drawImage() {
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        createWaveEffect();
    }
    function createWaveEffect() {
        const waveWidth = 20;
        const waveCount = 5;  
        let waveHeight = 20; 
        function animate() {}
        animate();
    }  
}

const canvasImgAnimatedTpl = (initData = {}) => {
    const { animateType, imgSrc } = initData;
    const mainStyles = getMainStyles(initData);
    const wrapperStyles = getWrapperStyles(initData);
    const canvasCmp = chef.cookCmp();
    canvasCmp.onCooked({ fn: () => { useAnimateWave(canvasCmp, imgSrc); } });
    return `<div ${wrapperStyles}>
              <canvas id="${canvasCmp.id}" ${mainStyles}></canvas>
           </div>`
}

export { canvasImgAnimatedTpl }