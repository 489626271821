import ComponentBase from './base';
import { 
    isFullArray,
    setValidCls,
    setInvalidCls
} from '../utils';

class ComponentField extends ComponentBase {
    constructor ({ 
        /** Base component prams */         
        id,
        el,
        html,
        handler,
        handlers,
        destroyWithEl,
        childCmps,
        keepedCmps,
        data,
        onReadyFns,
        type,
        rootElement,
        disabled,
        initConfig,
        /** Field component prams */  
        validator,
        fldType,
        fldRequires,
        fldName,
        fldLabel
    }) {
        super ({        
            id,
            el,
            html,
            handler,
            handlers,
            destroyWithEl,
            childCmps,
            keepedCmps,
            data,
            onReadyFns,
            type,
            rootElement,
            disabled,
            initConfig,
        });
        /* Add field events to base events*/
        this.events.fieldvaluechange = (data) => this.fieldValueChange(data);
        this.events.fieldvalueinput = (data) => this.fieldValueInput(data);
        this.eventsConnector.fieldvaluechange = (data) => this.onFieldValueChange(data);
        this.eventsConnector.fieldvalueinput = (data) => this.onFieldValueInput(data);
        this.fldType = fldType;
        this.fldRequires = fldRequires;
        this.fldName = fldName;
        this.fieldlabel = fldLabel;
        if (validator) { this.validator = validator; }
    }
    /** Elements: */
    setInputEl = function (el) {
        this.inputEl = el;
    }
    getInputEl = function () {
        let el = this.inputEl;
        if (!el) {
            el = this.queryEl('input');
            this.inputEl = el;
        }
        return el || null;
    }
    getIndicatorEl = function () {
        const el = this.indicatorEl;
        return el ? el : null;
    }
    /** Values: */
    getValue = function () {
        const el = this.getInputEl();
        return el ? el.value : '';
    }
    setValue = function (vl) {
        const el = this.getInputEl();
        if (el) { 
            el.value = vl; 
            el.checked = !!vl;
        }
    }
    /** Validates: */
    setValid = function () {
        const el = this.el;
        this.validValue = true;
        setValidCls(el);
    }
    setInvalid = function () {
        const el = this.el;
        this.validValue = false;
        setInvalidCls(el);
    }
    setValidator = function (validator) {
        this.validator = validator;
    }
    isValidValue = function () {
        if (this.validValue === undefined) { this.validate(); }
        return this.validValue;
    }
    validate =  function () {
        if (this.validator) { this.validValue = this.validator(); }
    }
    /** */
    resetField = function () {
        this.resetCls();
        this.setValue('');
        this.validValue = false;
    }
    /** Events process :*/
    /** Fields changes */
    onFieldValueInputFns = [];
    onFieldValueInput = function ({ fn, delay }) {
        this.collectHandlerFns({ collectFns: this.onFieldValueInputFns, fn, delay });
    }
    fieldValueInput =  function () {
        useCollectHandlerFns(this.onFieldValueInputFns);
    }
    onFieldValueChangeFns = [];
    onFieldValueChange = function () {
        this.collectHandlerFns({ collectFns: this.onFieldValueChangeFns, fn, delay });
    }
    fieldValueChange =  function () {
        this.useCollectHandlerFns(this.onFieldValueChangeFns);
    }
}

export default ComponentField;