import { chef } from '../../../../../index';
import classNames from '../../../../classNames';
import { isObject, joggOnArray, isArray, isString } from '../../../../../utils';
import './styles';

const { templates: { infoCard: infoCardClassNames }} = classNames;

const getMainStyles = (data = {}) => {
    let { cls, addCls, modCls, style, size } = data;
    addCls = addCls || '';
    if (size) {
        addCls = size === 's' ? ' ck-size-s' :
        size === 'm' ? ' ck-size-m' : size === 'l' ? ' ck-size-l' : ''
    }
    return chef.useCookedStyles({ cls: cls ? cls : 'ck-etc-list', addCls, modCls, style, joint: true });
};
const getItemsHtml = (data = {}) => {
    const { items, itemsHtml } = data;
    if (itemsHtml && isString(itemsHtml)) {
        return itemsHtml;
    } else if (isArray(items)) {
        let html = '';
        joggOnArray((items || []), (it) => { 
            if (it && isString(it)) {
                html += `<div class="ck-etc-list-item">${it}</div>`
            }
        });
        return html;
    } else {
        return '';
    }
};

const etcListTpl = (initData = {}) => {
    const { cmp, id } = initData;
    const mainCmp = chef.cookCmp({ cmp, id });
    const mainStyles = getMainStyles(initData);
    const itemsHtml = getItemsHtml(initData);
    
    return `<div id="${mainCmp.id}" ${mainStyles}>
               ${itemsHtml}
               <div class="ck-clipper-gradual"></div>
           </div>`;
}; 

export { etcListTpl }