import ComponentBase from './base';
import { 
    setExpandedCls,
    setCollapsedCls,
} from '../utils';

class ComponentPanel extends ComponentBase {
    constructor ({ 
        /** Base component prams */         
        id,
        el,
        html,
        handler,
        handlers,
        destroyWithEl,
        childCmps,
        keepedCmps,
        data,
        onReadyFns,
        type,
        rootElement,
        disabled,
        initConfig,
        /** Panel component params */  
        expanded
    }) {
        super ({        
            id,
            el,
            html,
            handler,
            handlers,
            destroyWithEl,
            childCmps,
            keepedCmps,
            data,
            onReadyFns,
            type,
            rootElement,
            disabled,
            initConfig,
        });
        this.expanded = !!expanded;
    }
    keepedHeaderCmp = null;
    keepedBodyCmp = null;
    keepedExpandBtnCmp = null;
    keepHeaderCmp = function (cmp) {
        this.keepedHeaderCmp = cmp;
    }
    getKeepedHeaderCmp = function () {
        return this.keepedHeaderCmp || null;
    }
    keepBodyCmp = function (cmp) {
        this.keepedBodyCmp = cmp;
    }
    getKeepedBodyCmp = function () {
        return this.keepedBodyCmp || null;
    }
    keepExpandBtnCmp = function (cmp) {
        this.keepedExpandBtnCmp = cmp;
    }
    getKeepedExpandBtnCmp = function () {
        return this.keepedExpandBtnCmp || null;
    }
    expand = function () {
        setExpandedCls(this.getEl());
        this.expanded = true;
        this.collapsed = false;
    }
    collapse = function () {
        setCollapsedCls(this.getEl());
        this.expanded = false;
        this.collapsed = true;
    }
    getExpanded =  function () {
        return this.expanded;
    }
    getCollapsed = function () {
        return this.collapse;
    }
}

export default ComponentPanel;