import theme from '../theme';
import { isArray } from './helpers';

const { 
    events: eventsClassNames,
    templates: templatesClassNames,
    state: stateClassNames
} = theme.classNames;

const setText = ({ el, text }) => {
  if (el) el.textContent = text;
};

const toggleCls = (el, clsAdd, clsRemove) => {
    if (el) {
        if (isArray(clsRemove)) {
            clsRemove.forEach((cls) => { el.classList.remove(cls); });
        } else {
            el.classList.remove(clsRemove); 
        }
        el.offsetWidth;
        el.classList.add(clsAdd);
    }
};

const removeCls = (el, cls) => {
    if (isArray(cls)) {
        cls.forEach((cls) => { el.classList.remove(cls); });
    } else {
        el.classList.remove(cls); 
    }
}

const setCls = (el, cls) => {
  if (el) {
      el.classList.remove(cls)
      el.offsetWidth;
      el.classList.add(cls);
  }
}
const setMissCls = (el, cls) => {
    if (el && !el.classList.contains(cls)) {
        el.classList.add(cls);
    }
}
const addCls = (el, cls) => {
    if (el) {
        el.classList.remove(cls)
        el.offsetWidth;
        el.classList.add(cls);
    }
}

const setOnShownCls = (el) => {
    setCls(el, eventsClassNames.onShown);
}
const setOnShowCls = (el) => {
    toggleCls(el, eventsClassNames.onShow, eventsClassNames.onHide);
    setMissCls(el, eventsClassNames.onShown);
}
const setOnHideCls = (el) => {
    toggleCls(el, eventsClassNames.onHide, eventsClassNames.onShow);
}

const setOnActiveCls = (el, removeForNearEls = true,  ) => {
    if (removeForNearEls) {
        const sls = Array.from(el.parentNode.childNodes);
        sls.forEach((el) => { el.classList.remove(eventsClassNames.onActive); });
    }
    addCls(el, eventsClassNames.onActive);

}
/** Buttons */
const setOnMouseenterCls = (el) => {
    removeCls(el, eventsClassNames.onMouseleave);
    addCls(el, eventsClassNames.onMouseenter);
}
const setOnMouseoverCls = (el) => {
    removeCls(el, eventsClassNames.onMouseleave);
    addCls(el, eventsClassNames.onMouseover);
}
const setOnMouseleaveCls = (el) => {
    removeCls(el, eventsClassNames.onMouseover);
    removeCls(el, eventsClassNames.onMouseenter);
    addCls(el, eventsClassNames.onMouseleave);
}
const setOnPressCls = (el) => {
  addCls(el, eventsClassNames.onPress);
    setTimeout(() => { removeCls(el, eventsClassNames.onPress)}, 800);
}
/* Fields */
const setOnSelectCls = (el) => {
    addCls(el,  eventsClassNames.onSelect);
}
const setOnFocusCls = (el) => {
    toggleCls(el, eventsClassNames.onFocus, eventsClassNames.onBlur);
}
const setOnBlurCls = (el) => {
    toggleCls(el, eventsClassNames.onBlur, eventsClassNames.onFocus);
}
/** Scroll */
const setOnShownScrolledCls = (el) => {
    setMissCls(el, eventsClassNames.onShownScrolled);
}
const setOnScrollInYCls = (el) => {
    toggleCls(el, eventsClassNames.onScrollInY, eventsClassNames.onScrollOutY);
}
const setOnScrollOutYCls = (el) => {
    toggleCls(el, eventsClassNames.onScrollOutY, eventsClassNames.onScrollInY);
}
const setOnScrollInXCls = (el) => {
    addCls(el,  eventsClassNames.onScrollInX);
}
const setOnScrollOutXCls = (el) => {
    addCls(el,  eventsClassNames.onScrollOutX);
}
/** Expande / Collapse */
const setExpandedCls = (el) => {
    toggleCls(el, eventsClassNames.onExpand, eventsClassNames.onCollapse);
}
const setCollapsedCls = (el) => {
    toggleCls(el, eventsClassNames.onCollapse, eventsClassNames.onExpand);
}
/* State */
const setAllowCls = (el) => {
    toggleCls(el, stateClassNames.allow, stateClassNames.deny);
}
const setDenyCls = (el) => {
    toggleCls(el, stateClassNames.deny, stateClassNames.allow);
}
const setErrorCls = (el) => {
    toggleCls(el, stateClassNames.error, stateClassNames.valid);
}
const setInvalidCls = (el) => {
    toggleCls(el, stateClassNames.invalid, stateClassNames.valid);
}
const setValidCls = (el) => { 
    setCls(el, stateClassNames.valid);
    removeCls(el, [stateClassNames.error, stateClassNames.invalid]);
}
const setEnabledCls = (el) => {
    toggleCls(el, stateClassNames.enabled, stateClassNames.disabled);
}
const setDisabledCls = (el) => {
    toggleCls(el, stateClassNames.disabled, stateClassNames.enabled);
}

export {
  setText,
  toggleCls,
  setCls, 
  addCls,
  removeCls,
  setOnShownCls,
  setOnShowCls,
  setOnHideCls,
  setOnActiveCls,
  setOnMouseenterCls,
  setOnMouseoverCls,
  setOnMouseleaveCls,
  setOnPressCls,
  setOnSelectCls,

  setOnFocusCls,
  setOnBlurCls,

  setOnShownScrolledCls,
  setOnScrollInYCls,
  setOnScrollOutYCls,
  setOnScrollInXCls,
  setOnScrollOutXCls,

  setAllowCls,
  setDenyCls,
  setValidCls,
  setErrorCls,
  setInvalidCls,
  setEnabledCls,
  setDisabledCls,

  setExpandedCls,
  setCollapsedCls,
};