import { chef } from '../../../../../index';
import { isObject, isString } from '../../../../../utils';
import iconsHtml from '../../../../../theme/images/icons/html';
import classNames from '../../../../classNames';
import { buttonIcoDownTpl, buttonIcoUpTpl } from '../../buttons';
import './styles';

const { templates: { expander: expanderClassNames, clipperGradual: clipperClassNames } } = classNames;

const getMainStyles = (data = {}) => {
  const { cls, addCls, style, modCls, expanded } = data; 
  const combAddCls = addCls ? expanded ? addCls +' ck-expanded' : addCls : '';
  return chef.useCookedStyles({ cls: cls ? cls : expanderClassNames.expander, modCls, addCls: combAddCls, style, joint: true });
};
const getExpandBtnData = (data = {}) => {
    const { expandBtnCmp, expanded } = data;
    if (isObject(expandBtnCmp)) {
        return [ expandBtnCmp, expandBtnCmp() ];
    } else {
        const newExpandBtnCmp = chef.cookCmp({ type: 'button' });
        return [
            newExpandBtnCmp,
            expanded ? buttonIcoUpTpl({ cmp: newExpandBtnCmp, addCls: 'ck-expander-expand-ico' })
            : buttonIcoDownTpl({ cmp: newExpandBtnCmp, addCls: 'ck-expander-expand-ico' })
        ]
    }
};

const expanderTpl = (data = {}) => {
    let { cmp, label, info, icoHtml, expanded } = data;
    const expCmp = chef.cookCmp({ cmp: cmp, type: 'expander', expanded });
    const [ expBtnCmp, expBtnHtml ] = getExpandBtnData(data);
    const labelCmp = chef.cookCmp();
    const infoCmp = chef.cookCmp();
    const mainStyles = getMainStyles(data);
    const onClickExpand = () => {
        if (expCmp.getExpanded()) { 
             expCmp.collapse(); 
             expBtnCmp.setIcoHtml(iconsHtml.icoDown);
        } else { 
            expCmp.expand(); 
            expBtnCmp.setIcoHtml(iconsHtml.icoUp);            
        }
    };
    expCmp.keepLabelCmp(labelCmp);
    expCmp.keepInfoCmp(infoCmp);
    expCmp.keepExpandBtnCmp(expBtnCmp);
    chef.cookHandler({ cmp: labelCmp, type: 'click', fn: onClickExpand });
    chef.cookHandler({ cmp: expBtnCmp, type: 'click', fn: onClickExpand });
    return `<div id="${expCmp.id}" ${mainStyles}>
               <div class="${expanderClassNames.labelContainer}">
                   ${icoHtml ? `<div class="${expanderClassNames.labelIco}">${icoHtml}</div>` : ''}
                   <div id="${labelCmp.id}" class="${expanderClassNames.label}">${label ? label : ''}</div>
                   ${expBtnHtml}
               </div>
               <div class="${expanderClassNames.infoContainer}">
                   <div id="${infoCmp.id}" class="${expanderClassNames.info}">${info ? info : ''}</div>
                   <div class="${clipperClassNames.clipper}"></div>
               </div> 
           </div>`;
};

export default expanderTpl;