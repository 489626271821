import ComponentBase from './base';
import classNames from '../theme/classNames';

class ComponentWindow extends ComponentBase {
    constructor ({        
        id,
        el,
        html,
        handler,
        handlers,
        destroyWithEl,
        childCmps,
        keepedCmps,
        data,
        onReadyFns,
        type,
        rootElement,
        initConfig
    }) {
        super ({        
            id,
            el,
            html,
            handler,
            handlers,
            destroyWithEl,
            childCmps,
            keepedCmps,
            data,
            onReadyFns,
            type,
            rootElement,
            initConfig
        });
    }
    keepedHeaderCmp = null;
    keepedTitleCmp = null;
    keepedContentCmp = null;
    keepedFooterCmp = null;
    keepedMaskCmp = null;
    keepTitleCmp = function (cmp) {
        this.keepedTitleCmp = cmp;
    }
    getKeepedTitleCmp = function () {
        return this.keepedTitleCmp;
    }
    keepHeaderCmp = function (cmp) {
        this.keepedHeaderCmp = cmp;
    }
    getKeepedHeaderCmp = function () {
        return this.keepedHeaderCmp;
    }
    keepContentCmp = function (cmp) {
        this.keepedContentCmp = cmp;
    }
    getKeepedContentCmp = function () {
        return this.keepedContentCmp;
    }
    keepFooterCmp = function (cmp) {
        this.keepedContentCmp = cmp;
    }
    getKeepedFooterCmp = function () {
        return this.keepedFooterCmp;
    }
    keepMaskCmp = function (cmp) {
        this.keepedMaskCmp = cmp;
    }
    getKeepedMaskCmp = function () {
        return this.keepedMaskCmp;
    }
    setTitleText = function (text) {
        const titleCmp = this.getKeepedTitleCmp();
        if (titleCmp && titleCmp.cmpType === 'title') {
            titleCmp.setText(text);
        } else {
            const { templates: { title: titleClassNames, windowModal } } = classNames;
            const winEl = this.getEl();
            const textEl = winEl ? winEl.querySelector(`.${windowModal.header} .${titleClassNames.text}`) : null;
            if (textEl) { textEl.textContent = text; }
        }
    }
    setContentHtml = function (html) {
        const contentCmp = this.getKeepedContentCmp();
        if (contentCmp) {
            contentCmp.setHtml(html);
        }
    }
}

export default ComponentWindow;