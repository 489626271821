import { chef, recipe } from '../../../../../../index';
import classNames from '../../../../../classNames';
import './styles';

const { templates: { button: buttonClassNames }} = classNames;
const getMainStyles = (data = {}) => {
    const { cls, addCls, style, modCls } = data;
    return chef.useCookedStyles({ cls: cls ? cls : buttonClassNames.buttonIco, addCls, modCls, style, joint: true });
}
const getIcoHtml = (data = {}) => {
    const { icoUrl, icoHtml } = data;
    return icoHtml ? icoHtml : icoUrl ? `<div style="background-image: url(${icoUrl});"></div>` : recipe.iconsHtml.icoStriped;
}

const buttonIcoBase = (initData = {}) => {
    const btnStyles = getMainStyles(initData);
    const icoHtml = getIcoHtml(initData);
    const cmp = chef.cookHandlers({ 
        ...initData, 
        defaultHandlers: { defaultsFor: 'button' } 
    }); 

    return `<div id="${cmp.id}" ${btnStyles}>
                <div class="${buttonClassNames.ico}">${icoHtml}</div>
            </div>`
}

export default buttonIcoBase;