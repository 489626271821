import { 
    createHandler,
    createHandlers
} from '../handlers';
import { 
    isArray,
    isObject, 
    addCls,
    removeCls,
    toggleCls,
    setOnShowCls,
    setOnHideCls,
    setEnabledCls,
    setDisabledCls,
    joggOnArray
} from '../utils';

class ComponentBase {
    constructor ({ 
        id,
        el,
        html,
        handler,
        handlers,
        destroyWithEl,
        childCmps,
        keepedCmps,
        data,
        onReadyFns,
        type,
        rootElement,
        disabled,
        initConfig
    }) {
        this.id = id || null;
        this.el = el || null;
        this.html = html || '';
        this.handler = handler || null;
        this.handlers = handlers || [];
        this.destroyWithEl = destroyWithEl === false ? false : true;
        this.childCmps = childCmps ? childCmps : [];
        this.childCmpsObj = childCmps ? childCmps : [];
        this.keepedCmps = keepedCmps ? keepedCmps : [];
        this.data = data && isObject(data) ? data : {};
        this.onReadyFns =  isArray(onReadyFns) ? onReadyFns : [];
        this.type = type ? type : 'default';
        this.rootElement = rootElement;
        this.initConfig = isObject(initConfig) ? initConfig : {};
        this.disabled = !!disabled;
        this.enabled = !disabled;
    }
    alive = true;
    destroyed = false;
    /** */
    setKeepedCmps = function (cmps) {
        this.keepedCmps = cmps;
    }
    /** */
    getKeepedCmps = function () {
        return this.keepedCmps || [];
    }
    /** */
    appendEl = function (el) { 
        if (this.el) { this.el.append(el); }
    }
    /** */
    prependEl = function (el) { 
        if (this.el) { this.el.prepend(el) }
    }
    /** */
    getEl = function () { 
        let el = this.el;
        if (!el) { el = document.querySelector('#'+this.id); }
        this.el = el;
        return this.el; 
    }
    /** */
    setEl = function (el) { 
        this.el = el || null;
    }
    /** */
    setHtml =  function (html) {
       const el = this.getEl();
       if (el) { el.innerHTML = html; }
    }
    /** */
    getChildrenEls = function () {
        return Array.from(this.el.children);
    }
    /* **/ 
    getElOffsetTop = function () {
        const el = this.getEl();
        return  el ? el.offsetTop : 0;
    }
    getElOffsetLeft = function () {
        const el = this.getEl();
        return  el ? el.offsetLeft : 0;
    }
    /** */
    findEl = function () {
        return document.querySelector(`#${this.id}`);
    }
    /** */
    setCls = function (cls) { 
        setCls(this.el, cls);
    }
    /** */
    addCls = function (cls) {
        addCls(this.el, cls);
    }
    /** */
        addCls = function (cls) {
        addCls(this.el, cls);
    }
    /** */
    removeCls = function (cls) {
        removeCls(this.el, cls);
    }
    /** */
    toggleCls = function (addCls, removeCls) {
        toggleCls(this.el, addCls, removeCls);
    }
    setStyle = function (style = '') {
       if (this.el) { this.el.style = style; }
    }
    /** */
    queryEl = function (query) { 
        return this.el ? this.el.querySelector(query) : null;
    }
    queryEls = function (query) {
        return this.el ? this.el.querySelectorAll(query) : [];
    }
    /** */
    getWidth = function (delay) {
        return this.el ? this.el.offsetWidth : 0;
    }
    /** */
    getElsWidth = function (delay) {
        const els = this.getChildrenEls();
        return els ? els.reduce(( w, it) => w + it.offsetWidth, 0) : 0;
    }
    /** */
    getHeight = function () {
        this.el ? this.el.offsetHeight : 0;
    }
    /** */ 
    setSetups = function (setups) {
        if (isObject(setups)) { this.setups = setups; }
    }
    /** Enable/Disable */ 
    disable = function () {
        setDisabledCls(this.getEl());
        this.disabled = true;
        this.enabled = false;
    }
    enable = function () {
        setEnabledCls(this.getEl());
        this.disabled = false;
        this.enabled = true;
    }
    getDisabled = function () {
        return this.disabled;
    }
    getEnanabled = function () {
        return this.enabled;
    }
    /** Return to initial classNames  */
    resetCls = function () {
        const el = this.getEl();
        if (el) {  el.classList = this.initialClassList; }
    }
    /* Events process: */
    events = {
        render: this.render,
        destroy: this.destroy,
        show: this.show,
        hide: this.hide,
        linkelement: this.linkEl
    }
    eventsConnector = {
        render: (data) => this.onRender(data),
        destroy: (data) => this.onDestroy(data),
        show: (data) => this.onShow(data),
        hide: (data) => this.onHide(data),
        linkelement: (data) => this.onLinkEl(data),
    }
    fireEvent = function (event, data) {
        const hasEvent = this.events[event]
        if (hasEvent) { hasEvent(data); }
    }
    collectHandlerFns = function ({ collectFns, fn, delay })  {
        const severalFns = isArray(fn);
        if (delay) {
            const getDelayedFn = (f) => () => { setTimeout(f, delay); };
            if (severalFns) {
                fn.forEach((f) => {collectFns.push(getDelayedFn(f));})
            } else { collectFns.push(getDelayedFn(fn)); }
        } else {
            if (severalFns) { collectFns.concat(fn); }
            else { collectFns.push(fn); }
        }
    }
    useCollectHandlerFns = (collectFns = [], data) => {
        joggOnArray(collectFns, (fn) => fn(data));   
    }
    /** Append component element to dom */
    onRenderFns = [];
    render = function () {
        const el = this.getEl();
        if (el) { 
            let classList = Array.from(el.classList);
            this.initialClassList = classList; 
        }
        this.useCollectHandlerFns(this.onRenderFns);
        this.rendered =  true;
    }
    onCooked = function ({ fn, delay }) { this.onRender({ fn, delay }); };
    onRender = function  ({ fn, delay }) { 
        this.collectHandlerFns({ collectFns: this.onRenderFns, fn, delay });
    }
    /** Desroy component */
    onDestroyFns = [];
    destroy = function () {
        this.useCollectHandlerFns(this.onDestroyFns);
        this.onDestroy()
        this.alive = false;
        if (this.destroyWithEl && this.el) { 
            this.el.remove();
            delete this.el;
        } 
        this.eventsManager.removeHandler({ id: this.handler.id, type: this.handler.type });
        this.eventsManager.removeHandlers({ id: this.handler.id, type: this.handler.type });
        delete this.handler;
        delete this.handlers;
    
    }
    onDestroy = function () {
        this.collectHandlerFns({ collectFns: this.onDestroyFns, fn, delay });
    }
    /** Linc elemenent with component */
    onLinkElFns = [];
    linkEl = function ({ el }) {
        this.el = el;
        this.render();
        this.useCollectHandlerFns(this.onLinkElFns);
    }
    onLinkEl = function ({ fn, delay }) {
        this.collectHandlerFns({ collectFns: this.onLinkElFns, fn, delay });
    }
    /** Component visibility */ 
    onShowFns = [];
    show = function () { 
        setOnShowCls(this.getEl()); 
        this.useCollectHandlerFns(this.onShowFns);
    }
    onShow = function () {
        this.collectHandlerFns({ collectFns: this.onShowFns, fn, delay });
    }
    onHideFns = [];
    hide = function () {
        setOnHideCls(this.getEl());
        this.useCollectHandlerFns(this.onHideFns);
    }
    onHide = function () {
        this.collectHandlerFns({ collectFns: this.onHideFns, fn, delay });
    }
}

export default ComponentBase;