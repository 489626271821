import { chef, dishes, spices, recipe } from 'cookery';
import privacyPolicyInfo from './privacyPolicyInfo';
import './styles';

const { sectionTpl } = dishes;

const privacyPolicy = (data = {}) => {
    return sectionTpl({ contentHtml: `<div class="ck-privacy-policy-info"> ${privacyPolicyInfo} </div>` });
};

export default privacyPolicy;