import header from '../../components/header';
import pageTitle from '../../components/pageTitle';
import footer from '../../components/footer';
import menu from '../../components/menu';
import { windowFeedback } from '../../components/windows';
import production from '../../components/production';
import feedback from '../../components/feedback';

const productionPage = () => {
    return `
        ${pageTitle({ title: 'Наша продукция' })}
        ${production()}
        ${feedback()}
        ${footer()}
        ${header()}
        ${menu()}
        ${windowFeedback()}
    `;
};

export default productionPage;