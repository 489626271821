import ComponentBase from './base';
import { 
    isFullArray,
    isObject, 
    joggOnArray,
    isNumber,
    getObjectFromArray
} from '../utils';

class ComponentForm extends ComponentBase {
    constructor ({   
        /** Base component prams */     
        id,
        el,
        html,
        handler,
        handlers,
        destroyWithEl,
        childCmps,
        keepedCmps,
        data,
        onReadyFns,
        type,
        rootElement,
        disabled,
        initConfig,
        /** Form component prams */
        formValidator,
        submitDelay
    }) {
        super ({        
            id,
            el,
            html,
            handler,
            handlers,
            destroyWithEl,
            childCmps,
            keepedCmps,
            data,
            onReadyFns,
            type,
            rootElement,
            disabled,
            initConfig
        });

        this.formDataValidated = false;
        this.formDataSendingBegin = false;
        this.formDataSendingComplete = false;
        this.formDisabled = false;
        this.submitDelay = submitDelay;
        /* Add form events to base events*/
        this.events.formdatachange = () => this.formDataChange();
        this.events.formdatasendingbegin = () => this.formDataSendingBegin();
        this.events.formdatasendingcomplete = () => this.formDataSendingComplete();
        this.eventsConnector.formdatachange = (data) => this.onFormDataChange(data);
        this.eventsConnector.formdatasendingbegin = (data) => this.onFormDataSendingBegin(data);
        this.eventsConnector.formdatasendingcomplete = (data) => this.onFormDataSendingComplete(data);
        /** Validate form on render */
        if (formValidator) {
            this.collectHandlerFns({ collectFns: this.onRenderFns, fn: () => { this.validateFormData = formValidator }, delay: formValidator.delay });
        } else { 
            this.collectHandlerFns({ collectFns: this.onRenderFns, fn: () => this.validateFormData() });
        }
    }
    keepedFieldCmps = [];
    keepedButtonCmps = [];
    keepFieldCmps = function (cmps) {
        if (isFullArray(cmps)) {
            this.keepedFieldCmps = cmps;
        } else {isObject(cmps)} {
            this.keepedButtonCmps.push(cmps);
        }
    }
    keepButtonCmps = function (cmps) {
        if (isFullArray(cmps)) {
            this.keepedButtonCmps = cmps;
        } else {isObject(cmps)} {
            this.keepedButtonCmps.push(cmps);
        }
    }
    getKeepedFieldCmps = function () {
        return this.keepedFieldCmps;
    }
    /** */
    validateFormData = function () {
        let i = 0;
        const fieldCmps = this.getKeepedFieldCmps(); 
        const fieldCmpsObj = getObjectFromArray(fieldCmps, 'fldName');
        let formDataValidated = false;

        const validateField = (fieldCmp) => {
            let isValidValue = fieldCmp.isValidValue();
            let fldRequires = fieldCmp.fldRequires;

            if (fldRequires === true) {
                return isValidValue;
            } else if (isObject(fldRequires)) {
                let hasValidReqires = true;
                if (fldRequires.or) { 
                    joggOnArray(fldRequires.or, (reqName) => {
                        const orFieldCmp = fieldCmpsObj[reqName]
                        if (!orFieldCmp || !orFieldCmp.isValidValue()) {
                            hasValidReqires = false;
                            return false;
                        }
                    });
                    if (!isValidValue && !hasValidReqires) { return false; }
                    else { return true; }
                }
            } else {  return true; }
        };

        while (i < fieldCmps.length) {
            const fieldCmp = fieldCmps[i]; 
            formDataValidated = validateField(fieldCmp);
            if (!formDataValidated) { break; }
            
            i++;
        }
        this.formDataValidated = formDataValidated;
        return formDataValidated;
    }
    /** Events process :*/
    /** Any fields value in the form changes */
    onFormDataChangeFns = [];
    onFormDataChange = function ({ fn, delay }) {
        this.collectHandlerFns({ collectFns: this.onFormDataChangeFns, fn, delay });
    }
    formDataChange = async (data = {}) => {
        const { ignoreValidateFormData } = data;
        if (!ignoreValidateFormData) { this.validateFormData(); }
        this.useCollectHandlerFns(this.onFormDataChangeFns)
    }
    /** Submit form */
    onSubmitFormFns = [];
    onSubmit = function ({ fn, delay }) {
        this.collectHandlerFns({ collectFns: this.onSubmitFormFns, fn, delay });
    }
    collectData = function (asFormData) {
        const fieldCmps = this.getKeepedFieldCmps();
        const data = {};
        const formData = new FormData();

        if (this.formDataValidated) {
            joggOnArray(fieldCmps, (cmp) => {
                const key = cmp.fldName;
                const value = cmp.getValue();
                if (key) { 
                    data[key] = value; 
                    formData.set(key, value);
                }
            });
        }
        return asFormData ? formData : data;
    }
    getData = function () {
        return this.collectData();
    }
    getFormData =  function () {
        return this.collectData(true);
    }
    setSubmitDelay = function (delay) {
        this.submitDelay = delay;
    }
    submit = function () { 
        const me = this;
        const submitData = me.getData();
        const delay = me.submitDelay;

        me.submitData = submitData;
        if (delay && isNumber(delay)) {
            setTimeout(() => {
                me.useCollectHandlerFns(me.onSubmitFormFns, submitData);
            }, delay);
        } else {
            me.useCollectHandlerFns(me.onSubmitFormFns, submitData);
        }
    }
    /** Reset all filed values */
    onResetFormDataFns = [];
    onResetFormData = function ({fn, delay }) {
        this.collectHandlerFns({ collectFns: this.onResetFormDataFns, fn, delay });
    }
    resetFormData = async function () { 
        const cmps = await this.getKeepedFieldCmps();
        joggOnArray(cmps, (cmp) => { cmp.resetField(); });
        this.formDataValidated = false;
        this.formDataChange({ ignoreValidateFormData: true });
        /** No needed yet */
        /* this.useCollectHandlerFns(this.onResetFormDataFns); */
    }
}

export default ComponentForm;