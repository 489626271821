import header from '../header';
import footer from '../footer';
import greeting from './greeting';
import about from './about';
import advantagesA from './advantagesA';
import advantagesB from './advantagesB';
import production from './production';
import feedback from '../feedback';
import menu from '../menu';
import { windowFeedback } from '../windows';
import './styles';

const mainPage = () => {
    return `
      ${greeting()}
      ${about()}
      ${advantagesA()}
      ${advantagesB()}
      ${production()}
      ${feedback()}
      ${footer()}
      ${header()}
      ${menu()}
      ${windowFeedback()}
    `;
}

export default mainPage;
