import { chef } from '../../../../../index';
import { isObject } from '../../../../../utils';
import iconsHtml from '../../../../../theme/images/icons/html';
import { buttonIcoDownTpl, buttonIcoUpTpl } from '../../buttons';
import './styles';

const getMainStyles = (data = {}) => {
  let { cls, addCls = '', style, modCls, expanded, disabled } = data; 
  addCls += expanded !== false ? ' ck-expanded' : ''; 
  addCls += disabled ? ' ck-disabled' : '';
  return chef.useCookedStyles({ cls: cls ? cls : 'ck-panel', modCls, addCls, style, joint: true });
};
const getExpandBtnData = (data = {}) => {
    let { expandBtnCmp, expanded } = data;
    expanded = expanded !== false;
    if (isObject(expandBtnCmp)) {
        return [ expandBtnCmp, expandBtnCmp() ];
    } else {
        const newExpandBtnCmp = chef.cookCmp({ type: 'button' });
        return [
            newExpandBtnCmp,
            expanded ? buttonIcoUpTpl({ cmp: newExpandBtnCmp, addCls: 'ck-panel-expand-ico' })
            : buttonIcoDownTpl({ cmp: newExpandBtnCmp, addCls: 'ck-panel-expand-ico' })
        ]
    }
};

const panelTpl = (initData = {}) => {
    let { cmp, title, header, bodyHtml, contentHtml, expanded, disabled } = initData;
    expanded = expanded !== false;
    const panelCmp = chef.cookCmp({ cmp: cmp, type: 'panel', expanded, disabled }); 
    const headerCmp = chef.cookCmp();
    const bodyCmp = chef.cookCmp();
    const [ expBtnCmp, expBtnHtml ] = getExpandBtnData(initData);

    const mainStyles = getMainStyles(initData);
    const bodyContentHtml = bodyHtml || contentHtml || '';
    const onClickExpand = () => {
        if (panelCmp.getExpanded()) { 
             panelCmp.collapse(); 
             expBtnCmp.setIcoHtml(iconsHtml.icoDown);
        } else { 
            panelCmp.expand(); 
            expBtnCmp.setIcoHtml(iconsHtml.icoUp);            
        }
    };
    panelCmp.keepHeaderCmp(headerCmp);
    panelCmp.keepBodyCmp(bodyCmp);
    panelCmp.keepExpandBtnCmp(expBtnCmp);
    chef.cookHandler({ cmp: expBtnCmp, type: 'click', fn: onClickExpand });
    return `<div id="${panelCmp.id}" ${mainStyles}>
               <div class="ck-panel-border"></div>
               <div class="ck-panel-header">
                   <div class="ck-panel-header-title">${title || ''}</div>
                   <div class="ck-panel-header-tools">${expBtnHtml}</div>
               </div>
               <div id="${bodyCmp.id}" class="ck-panel-body">
                   <div class="ck-panel-body-inner">${bodyContentHtml}</div>
               </div>
               <div class="ck-disabler"></div> 
           </div>`;
};

export { panelTpl };