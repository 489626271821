import { isArray, isObject, isDifferent } from '../utils';

class StorageManager {
    setOnlyDifferentValues = false
    bindWidthDataTree = false
    binds = {}
    data = {} 
    /** */
    setAll = function (data) { this.data = data; }
    getAll = function () { return this.data; }
    /** */
    get = function (key) {
        const keys = key.split('.');
        if (keys.length) {
            if (keys.length === 1) { return this.data[keys[0]] || null; }
            else { return this.getRootValue(keys); }
        } else {
            return null;
        } 
    }
    /** */
    set = function (key, val, props = {}) {
        const { delay } = props;
        const keys = key.split('.');
        const process = () => {
            if (keys.length === 1) { 
                if (isDifferent(this.data[keys[0]], val)) {
                    this.data[keys[0]] = val;
                    this.processBinds(key, val);
                }
            } else { this.setRootValue(key, keys, val); }
        }

        
        if (keys.length) {
            if (delay) { setTimeout(() => process(), delay); }
            else { process(); }
        }
    }
    /** */
    processBinds = function (key, val) { 
        const bind = this.binds[key];
        if (bind) { 
             bind.fns.forEach((it) => { 
                 const { fn, props } = it; 
                 const delay = props ? props.delay : null;
                 if (delay) { setTimeout(() => { fn(val); }, delay); }
                 else { fn(val); }
             });
        } 
     }
    /** */
    bind = function (key, fn, props) {
        const bind = this.binds[key];
        const findDataProp = this.findDataProp(key);

        if (findDataProp) {
            if (bind) { bind.fns.push({ fn, props });
            } else { this.binds[key] = { fns: [{fn, props}] } }
            return true;
        } else { return false; }
    }
    /** */
    unbind = function (key, fn) {
        const bind = this.binds[key];
        if (bind) { 
            bind.fns.filter( it => it !== fn); 
            if (!bind.fns.length) { delete this.binds[key]; }
        }
    }
    /** */
    findDataProp = function (key) {
        const data = this.data;
        const dataSteps = [];
        const keys = key.split('.');
        let findKey = true;

        for (let i=0; i < keys.length; i++) {
            let dataStep = data[keys[i]];

            if (dataStep !== undefined) {
                dataSteps.push(dataStep);
            } else if (isArray(dataSteps)) {
                dataStep = dataSteps[i-1][keys[i]]
                if (dataStep !== undefined) { dataSteps.push(dataStep); }
                else { findKey = false; break; }
            } else { findKey = true; break; }
        }
        return findKey;
    }
    /** */
    getRootValue = function (keys) {
        const data = this.data;
        const dataSteps = [];
        let val = null;

        for (let i=0; i < keys.length; i++) { 
            const isLastKey = i === keys.length-1;
            const currKey = keys[i];
            let dataStep = i == 0 ? data[currKey] : dataSteps[i-1][currKey];

            if (dataStep === undefined ) { 
                val = null; 
                break; 
            } else {
                if (isLastKey) { val = dataStep; } 
                else if (isObject(dataStep)) { dataSteps.push(dataStep); }
                else {  val = null; break; }
            }
        }

        return val;   
    }
    /** */
    setRootValue = function (key, keys, val) {
        const data = this.data;
        const dataSteps = [];

        for (let i = 0; i < keys.length; i++) {
            const isLastKey = i === keys.length-1;
            const currKey = keys[i];
            let dataStep = data[currKey];

            if (dataStep !== undefined ) {
                if (isLastKey) { 
                    if (isDifferent(dataSteps[i], val)) {
                        dataSteps[i] = val; 
                        this.processBinds(key, val);
                    }
                } else if (isObject(dataStep)) { dataSteps.push(dataStep); }
            } else {
                dataStep = {}
                if (dataSteps.length) { 
                    if (isLastKey) {
                        if (isDifferent(dataSteps[i-1][currKey], val)) {
                            dataSteps[i-1][currKey] = val;
                            this.processBinds(key, val); 
                            
                        }
                    } else { dataSteps[i-1][currKey] = {}; }
                } else {
                    if (isDifferent(data[currKey], val)) { data[currKey] = dataStep; }
                }
                dataSteps.push(dataStep);
            }
        }
    } 
    /** */
    init = function () {}
    /** */
    constructor () {}
}

export default StorageManager;