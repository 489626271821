import { chef } from "../../../../../..";
import { maskTpl } from "../../../other";
import { buttonIcoCloseTpl } from "../../../buttons";
import { isFullArray, isObject, joggOnObject } from '../../../../../../utils';
import classNames from "../../../../../classNames";
import './styles';

const { templates: { menuModal: menuModalClsNames, overlapper: overlapperClsNames }} = classNames;

const getMenuItemsData = (data = {}) => {
    const { itemsData = {} } = data;
    const { items, html } = itemsData;
    let itemsHtml = html ? html : '';
    let itemsCmps = [];

    if (itemsHtml) { return [ [], itemsHtml ]; }
    else if (isObject(items)) {
        joggOnObject(items, (key, it) => { 
            const cmp = chef.cookHandler({ type: 'click', defaultHandlers: { defaultsFor: 'button' }, fn: () => chef.goTo({ url: key }) });
            itemsCmps.push(cmp);
            itemsHtml += !it.extra ? `<li id="${cmp.id}" class="${menuModalClsNames.item}">${it.name}</li>` : ''
        });
        if (itemsHtml) {
            itemsHtml = `<ul class="${menuModalClsNames.items}">${itemsHtml}</ul>`;
        }
        return [ itemsCmps, itemsHtml ];
    } else { return [ [], '' ]; } 
}

const menuModalTpl = (initData = {}) => {
    const { cmp, id, binds } = initData;
    const menuCmp = chef.cookCmp({ cmp, id });
    const showingBindKey = binds && binds.show;
    const onClosekHandlerFn = showingBindKey ? () => chef.set(showingBindKey, false ) : () => menuCmp.hide();
    const onClosekHandler = { type: 'click', fn: () => onClosekHandlerFn() };
    const [ itemsCmps, itemsHtml ] = getMenuItemsData(initData);
    if (showingBindKey) {
        chef.bind(showingBindKey, (vl) => {
            if (vl) { menuCmp.show(); }
            else { menuCmp.hide() }
        })
    }
    return `<div id="${menuCmp.id}" class="${menuModalClsNames.wrapper}">
              ${maskTpl({ opacity: 0.7, addCls: 'ck-menu-modal-mask', handler: onClosekHandler })}
              <div class="${menuModalClsNames.menuModal}">
                ${buttonIcoCloseTpl({ handler: onClosekHandler })}
                ${itemsHtml}
                <div class="${overlapperClsNames.overlapper}"></div>
              </div>
           </div>`
}

export default menuModalTpl; 