import { chef } from '../../../../../index';
import { joggOnArray } from '../../../../../utils';
import { getFields } from './fields';
import { getButtons } from './buttons';
import classNames from '../../../../classNames'
import iconsHtml from '../../../../images/icons/html';

import './styles';

const { templates: { form: formClassNames } } = classNames;
const getMainStyles = (data = {}) => {
    const { cls, addCls, style } = data;
    return chef.useCookedStyles({ cls: cls ? cls : formClassNames.form, addCls, style, joint: true });
}

const formTpl = (initData = {}) => {
    let { cmp, id, html } = initData;
    const formCmp = cmp ? cmp : chef.cookCmp({ id, type: 'form' });
    const mainStyles = getMainStyles(initData);
    const [ buttonsHtml, buttonCmps ] = getButtons({ ...initData, formCmp });
    const [ fieldsHtml, fieldCmps ] = getFields({ ...initData, formCmp });

    formCmp.keepFieldCmps(fieldCmps); 
    formCmp.keepButtonCmps(buttonCmps); 
    chef.cookHandler({
        cmp: formCmp,
        type: 'formdatachange',
        fn: () => {
            joggOnArray(buttonCmps.submit, (btnCmp) => {
                if (formCmp.formDataValidated) { btnCmp.enable(); }
                else { btnCmp.disable(); }
            });
        }
    });
    return html ? `<form id="${formCmp.id}" ${mainStyles}>${html}</form>` 
           : `<form id="${formCmp.id}" ${mainStyles}>
                <div class="${formClassNames.fields}">${fieldsHtml}</div>
                <div class="${formClassNames.info}"></div>
                ${buttonsHtml ? `<div class="${formClassNames.buttons}">${buttonsHtml}</div>` : ''}   
                <div class="${formClassNames.disabler}">${iconsHtml.icoLoader}</div>
             </form>`
}

export default formTpl;