import { chef } from '../../../../../index';
import { isFullArray, joggOnArray } from '../../../../../utils';
import {
    fieldTextTpl,
    fieldTextareaTpl,
    fieldComboTpl,
    fieldRadioGroupTpl,
    fieldCheckBoxTpl
} from '../../fields'

const createFieldCmp = (config = {}) => {
    let { cmp, id, fldType, formCmp } = config; 

    cmp = cmp ? cmp : chef.cookCmp({ ...config, id, type: 'field', formCmp });
    id = cmp ? cmp.id : id ? id : chef.cookId();
    if (fldType === 'textarea') {
        return [ cmp, fieldTextareaTpl({ ...config, cmp, id }) ];
    } else if (fldType === 'combo') {
        return [ cmp, fieldComboTpl({ ...config, cmp, id }) ];
    } else if (fldType === 'radiogroup') {
        return [ cmp, fieldRadioGroupTpl({ ...config, cmp, id }) ];
    }  else if (fldType === 'checkbox') {
        return [ cmp, fieldCheckBoxTpl({ ...config, cmp, id }) ];
    } else {
       return [ cmp, fieldTextTpl({ ...config, cmp, id }) ];
    }
};

const getFields = (data = {}) => {
    const { formCmp, fieldsConfig } = data;
    const { fields, html } = fieldsConfig;
    let htmlCollect = ''; 
    let cmps = [];
    const hasFields = isFullArray(fields);

    if (html) {
        return [ html, [] ];
    } else if (hasFields) {
        joggOnArray(fields, (config) => { 
            const [ cmp, html ] = createFieldCmp({...config, formCmp: config.formCmp || formCmp });
            htmlCollect += html;
            cmps.push(cmp);
        }); 
        return [ htmlCollect, cmps ]
    } else {
        return [ '', [] ];
    }
}

export { getFields }