import { chef, dishes, cookbook } from 'cookery';
import { api } from 'utils';
import './styles';

const { windowTpl, formTpl, buttonTpl } = dishes;

const windowFeedback = (initData = {}) => {
    const winCmp = chef.cookCmp({ type: 'window' });
    const formCmp = chef.cookCmp({ type: 'form' });
    const linkCmp = chef.cookHandler({ type: 'click', fn: () => chef.goTo({ url: 'privacy-policy', blankPage: true }) });
    chef.bind('windowFeedbackShow', (vl) => {
        if (vl) { winCmp.show(); }
        else { 
            winCmp.hide();
            formCmp.resetFormData();  
        }
    });
    formCmp.setSubmitDelay(500);
    formCmp.onSubmit({ fn: (submitData) => {
        formCmp.disable(); 
        api.requestFeedback(submitData).then((resp) => {
            const error = resp.error;
            const success = resp.success;
            formCmp.enable();
            if (success) {
                chef.set('windowFeedbackShow', false);
                formCmp.resetFormData();
                chef.shout({ title: 'Заявка отправленна', message: 'Наши специалисты свяжутся с вами в ближайшее время!'});
            } else if (error === 'recaptcha') {
                chef.shout({ title: 'Ошибка', message: 'Кажется вы не человек...'});
            } else {
                chef.shout({ title: 'Ошибка', message: 'Ошибка отправки сообщения...'}); 
            }
        }).catch((err) => {
            formCmp.enable();
            chef.shout({ title: 'Ошибка', message: 'Ошибка отправки сообщения...'}); 
        });
    } });

    return windowTpl({ 
        ...initData, 
        title: { text: 'Обратная связь', align: 'center' }, 
        cmp: winCmp, 
        addCls: 'ck-feedback-win',
        binds: { showing: 'windowFeedbackShow' }, 
        contentHtml: formTpl({
            cmp: formCmp,
            useRecaptcha: true,
            fieldsConfig: {
                fields: [
                    { fldType: 'text', fldRequires: true, fldName: 'name', fldInitValue: '', fldLabel: 'Имя', fldFormat: 'name' },
                    { fldType: 'text', fldRequires: { or: ['email'] }, fldName: 'phone',   fldInitValue: '', fldLabel: 'Телефон', fldFormat: 'phone' },
                    { fldType: 'text', fldRequires: { or: ['phone'] }, fldName: 'email',   fldInitValue: '', fldLabel: 'E-mail', fldFormat: 'email' },
                    { fldType: 'textarea', fldRequires: false, fldName: 'message', fldInitValue: '', fldLabel: 'Сообщение', fldFormat: 'info'  },
                    { fldType: 'checkbox', fldValidValue: true, fldRequires: true, fldName: 'agree', fldInitValue: false, fldLabel: `Соглашаюсь с <a id="${linkCmp.id}">политикой</a> обработки персональных данных` },
                ]
            },
            buttonsConfig: {
                defaults: [{ 
                    btnType: 'submit', 
                    btnColor: 'white',
                    btnTpl: buttonTpl
                }],
            }
        })
    });
}

export default windowFeedback;