import { chef  } from '../../../../../../index';
import { setOnPressCls } from '../../../../../../utils';
import iconsHtml from '../../../../../../theme/images/icons/html';
import './styles';

const getMainStyles = (data = {}) => {
    const { cls, addCls, style } = data;
    return chef.useCookedStyles({ cls: cls ? cls : 'ck-button-link', addCls, style, joint: true });
}

const buttonLinkTpl = (initData = {}) => {
    let { text, link, ico } = initData; console.log(link)
    const btnStyles = getMainStyles(initData);
    const cmp = chef.cookHandlers({ ...initData, handler: {
         type: 'click', fn: (e) => {
            setOnPressCls(e.currentTarget);
            window.open(link, '_blank');
         }
    }});
        
    return `<div id="${cmp.id}" ${btnStyles}>
              <div class="ck-text">${text ? text : '...'}</div>
              <div class="ck-ico">${ico ? ico : iconsHtml.icoLinkA}</div>
           </div>`
}

export default buttonLinkTpl;