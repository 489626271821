import { chef, dishes } from 'cookery';
import './styles';

const { sectionTpl, titleSectionTpl } = dishes;

const getAboutInfoHtml = () => {
    const imageCmp = chef.cookHandlers({ defaultHandlers: { defaultsFor: 'scrolled' } });

    return `<div class="ck-advantages-info-container">
                <div id="${imageCmp.id}" class="ck-advantages-image">
                  <div><div></div></div>
                  <div><div></div></div>
                </div>
                <div class="ck-advantages-info">
                  ${titleSectionTpl({ text: 'Интегрированные подходы', size: 'l',  align: 'left', color: 'black', addCls: 'ck-advantages-title-section' })}
                  <p>Мы предлагаем комплексные услуги, включающие поставку оборудования первого и второго уровней для проектов в областях тепловой и электроэнергетики, а также для нужд капитального строительства и технического обновления</p>
                  ${titleSectionTpl({ text: 'Эффективная организация<br /> поставок', size: 'l', align: 'left', color: 'black', addCls: 'ck-advantages-title-section' })}
                  <p>Гарантируем нашим заказчикам высокое качество обслуживания, включая точное соблюдение сроков, эффективное управление поставками и всестороннюю поддержку для успешной реализации проектов "под ключ".</p>
                </div>
            </div>`;
}

const advantages = () => {
    return sectionTpl({ id: 'advantages-a', contentHtml: getAboutInfoHtml(), addCls: 'ck-advantages' });
}

export default advantages; 