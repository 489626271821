const getDefaultClassNames = (main) => {
    const classNames = {};
    const mainPrefix = 'ck';
    main = `${mainPrefix}-${main}`;
    classNames.main = `${main}`
    classNames.mainInner = `${main}-inner`
    classNames.mainWrapper = `${main}-wrapper`;
    classNames.header = `${main}-header`;
    classNames.headerInner = `${main}-header-inner`;
    classNames.headerWrapper = `${main}-header-wrapper`;
    classNames.body = `${main}-body`;
    classNames.bodyInner = `${main}-body-inner`;
    classNames.bodyWrapper = `${main}-body-wrapper`;
    classNames.footer = `${main}-footer`;
    classNames.footerInner = `${main}-footer-inner`;
    classNames.footerWrapper = `${main}-footer-wrapper`;
    classNames.info = `${main}-info`;
    classNames.infoInner = `${main}-info-inner`;
    classNames.infoWrapper = `${main}-info-wrapper`;
    classNames.ico = `${main}-ico`;
    return classNames;
}

const classNames = {
    events: {
        onClick: 'ck-press',
        onPress: 'ck-press',
        onMouseover: 'ck-over',
        onMouseenter: 'ck-enter',
        onMouseleave: 'ck-leave',
        onDisable: 'ck-disable',
        onShown: 'ck-shown',
        onShow: 'ck-show',
        onShowfirst: 'ck-show-first',
        onHide: 'ck-hide',
        onBlink: 'ck-blink',
        onActive: 'ck-active',
        onSelect: 'ck-select',
        onShownScrolled: 'ck-shown-scrolled',
        onScrollInY: 'ck-scroll-in-y',
        onScrollOutY: 'ck-scroll-out-y',
        onScrollInX: 'ck-scroll-in-x',
        onScrollOutX: 'ck-scroll-out-x',
        onFocus: 'ck-focus',
        onBlur: 'ck-blur',
        onCollapse: 'ck-collapsed',
        onExpand: 'ck-expanded'
    },
    colors: {
        main: 'ck-color-main', 
        mainContrast: 'ck-color-main-contrast',
        second: 'ck-color-second',
        secondContrast: 'ck-color-second-contrast',
        black: 'ck-color-black',
        white: 'ck-color-white',
    },
    templates: {
       clipperGradual: {
           clipper: 'ck-clipper-gradual',
       },
       mask: {
           mask: 'ck-mask'
       },
       button: {
            wrapper: 'ck-button-wrapper',
            button: 'ck-button',
            buttonIco: 'ck-button-ico',
            text: 'ck-button-text',
            ico: 'ck-ico',
            info: 'ck-button-info',
            disabler: 'ck-button-disabler'
        },
        title: {
            title: 'ck-title',
            text: 'ck-title-text'
        },
        titleWindow: {
            title: 'ck-title-window',
            text: 'ck-title-text',
            tools: 'ck-title-tools'
        },
        titleSection: {
            title: 'ck-title-section',
            text: 'ck-title-text',
            tools: 'ck-title-tools'
        },
        label: {
             label: 'ck-label',
             text: 'ck-label-text',
             ico: 'ck-label-ico'
        },
        window: {
            window: 'ck-window',
            header: 'ck-window-header',
            content: 'ck-window-content',
            footer: 'ck-window-footer',
            mask: 'ck-window-mask'
        },
        windowModal: {
            wrapper: 'ck-window-modal-wrapper',
            mainMask: 'ck-window-modal-mask',
            window: 'ck-window-modal',
            header: 'ck-window-header',
            content: 'ck-window-content',
            footer: 'ck-window-footer',
            mask: 'ck-window-mask'
        },
        form: {
            form: 'ck-form',
            buttons: 'ck-form-buttons',
            fields: 'ck-form-fields',
            info: 'ck-form-info',
            disabler: 'ck-form-disabler'
        },
        field: {
            field: 'ck-field',
            input: 'ck-field-input',
            label: 'ck-field-label'
        },
        expander: {
            expander: 'ck-expander',
            labelContainer: 'ck-expander-label-container',
            label: 'ck-expander-label',
            labelIco: 'ck-expander-label-ico',
            expandIco: 'ck-expander-expand-ico',
            infoContainer: 'ck-expander-info-container',
            info: 'ck-expander-info',
            gradualСlipper: 'ck-expander-gradual-clipper'  
        },
        panel: {
            panel: 'ck-panel',
            header: 'ck-panel-header',
            title: 'ck-panel-title',
            titleIco: 'ck-panel-title-ico',
            body: 'ck-panel-body',
            bodyInner: 'ck-panel-body-inner',
            expandIco: 'ck-panel-expand-ico',
            gradualСlipper: 'ck-panel-gradual-clipper'  
        },
        infoCard: {
            infoCard: 'ck-info-card',
            title: 'ck-info-card-title',
            items: 'ck-info-card-items',
            item: 'ck-info-card-item',
            itemName: 'ck-info-card-item-name',
            itemValue: 'ck-info-card-item-value',
        },
        menuModal: {
            wrapper: 'ck-menu-modal-wrapper',
            menuModal: 'ck-menu-modal',
            items: 'ck-menu-modal-items',
            item: 'ck-menu-modal-item',
        },
        overlapper: {
            overlapper: 'ck-overlapper'
        },
        canvas:{
            wrapper: 'ck-canvas-wrapper', 
            canvas: 'ck-canvas', 
        }
    },
    state: {
        valid: 'ck-valid',
        invalid: 'ck-invalid',
        error: 'ck-error',
        allow: 'ck-allow',
        deny: 'ck-deny',
        enabled: 'ck-enabled',
        disabled: 'ck-disabled'        
    },
}

export default classNames;