import { chef, recipe } from '../../../../../../../index';
import classNames from '../../../../../../classNames';
import './styles';

const { templates: { button: buttonClassNames }} = classNames;

const getMainStyles = (data = {}) => {
    let { cls, addCls, modCls = '', style, rounded, btnColor, txtColor } = data;

    if (rounded) { modCls += ' ck-button-rounded'; }
    if (btnColor == 'main') { modCls += ' ck-button-color-main'; }
    else if (btnColor == 'second') { modCls += ' ck-button-color-second'; }
    else if (btnColor == 'white') { modCls += ' ck-button-color-white'; }
    else if (btnColor == 'black') { modCls += ' ck-button-color-black'; }

    if (txtColor == 'main') { modCls += 'ck-button-text-color-main'; }
    else if (txtColor == 'second') { modCls += ' ck-button-text-color-second'; }
    else if (txtColor == 'white') { modCls += ' ck-button-text-color-white'; }
    else if (txtColor == 'black') { modCls += ' ck-button-text-color-black'; }

    return chef.useCookedStyles({ cls: cls ? cls : `ck-button-special-animated-2 ${modCls}`, addCls, style, joint: true });
}

const buttonSpecialAnimated2Tpl = (initData = {}) => {
    const { text } = initData;
    const btnStyles = getMainStyles(initData);   
    const cmp = chef.cookHandlers({ 
      ...initData, 
      defaultHandlers: { defaultsFor: 'button' } 
    });
  
    return `<div id="${cmp.id}" ${btnStyles}>
              <div class="ck-animated-ellipses"><div></div><div></div><div></div></div>
              <div class="ck-animated-ellipses"><div></div><div></div><div></div></div>
              <div class="ck-animated-lines"><div></div><div></div><div></div></div>
              <div class="ck-button-background"><div></div></div>
              <div class="${buttonClassNames.text}">${text || '...'}</div>
              <div class="ck-ico-right">${recipe.iconsHtml.icoRight}</div>
              <div class="${buttonClassNames.disabler}"></div>
           </div>`
  }

  export default buttonSpecialAnimated2Tpl;