import { dishes} from 'cookery';
import { logoFooter } from '../logo';
import partnerMapDocUrl from '../../docs/sck.docx';
import './styles'

const { footerTpl } = dishes;

const footer = (initData = {}) => {
    return footerTpl({ 
        logoHtml: logoFooter(), 
        buttons: { feedback: { off: true } },
        partnerMapDocUrl
    });
}

export default footer;