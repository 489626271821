import { chef } from '../../../../../index';
import './styles';

const getMainStyles = (data = {}) => {
  let { cls, addCls, style, initShow } = data;
  addCls = addCls ? [addCls, initShow ? 'ck-show' : 'ck-hide'] : initShow ? 'ck-show' : 'ck-hide';
  return chef.useCookedStyles({ cls: cls ? cls : 'ck-mask', addCls, style, joint: true });
}

const maskTpl = (initData = {}) => {
    let { cmp, id, html, opacity } = initData;
    const mainStyles = getMainStyles(initData);
    cmp = chef.cookHandlers(initData);
    
    return `<div id="${cmp.id}" ${mainStyles}>
             <div class="ck-mask-background" style="opacity:${opacity ? opacity: 0.5}"></div>
             ${html ? html : ''}
           </div>`;
} 

export { maskTpl }