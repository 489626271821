import { dishes, recipe, spices } from 'cookery';
import productListData from './productListData';
import './styles';

const { expanderModCTpl, etcListTpl } = dishes;
const { iconsHtml } = recipe;
const { joggOnArray } = spices;

const productList = (data ={}) => {
    const getProductCards = () => {
        let html = '';
        joggOnArray(productListData, (it, idx) => {
            html += `<div class="ck-product-item">
              ${it.label}
              ${etcListTpl({ items: it.items, size: 'm' })}
            </div>`
        });
        return html;
    }
    return `<div class="ck-product-list">${getProductCards()}</div>`;
}

export default productList;