import { chef } from '../../../../../index';
import { isObject } from '../../../../../utils';

const validateNameValue = ({ value, min = 3, el }) => {
    if ((value.length >= min) && (/^[А-я`'\s\.Ёё-]+$/.test(value))) { 
        return true; 
    } else { return false; }
}
const validatePhoneValue = ({ value, el }) => {
    let firstChar = value[0];
    let limit = 12;

    if (firstChar === '7' && value.length === 1) { 
        value = '+' + value; 
        el.value = value;
        limit = 13;
    } else if (firstChar === '8') {
        limit = 11;
    } else { 
        limit = 12; 
    }
    if ((value.length <= limit) && (/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/.test(value)) ) {
        return true;
    } else { return false; }
}
const validateEmailValue = ({ value, lel }) => {
    if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
        return true;
    } else { return false; }
}

const validateValue = ({ fldFormat, fldType, min, fldRequires, el }) => {
    let isValid = false;
    let value = el.value;
    let checked = el.checked;

    switch (true) {
        case fldFormat === 'name': 
            isValid = validateNameValue({ value, min, el });
            break;
        case fldFormat === 'phone':
            isValid = validatePhoneValue({ value, el });
            break;
        case fldFormat === 'email':
            isValid = validateEmailValue({ value, el }); 
            break; 
        case fldType === 'checkbox': 
            isValid = !(fldRequires === true && !checked);
            break;
        default:
            isValid = true;
    }

    return isValid;
}

const validateField = ( data = {} ) => { 
    let { formCmp, fieldCmp, inputCmp, fldFormat, fldType, min,  fldRequires, e } = data;
    let inputEl = e ? e.target : inputCmp.getEl();

    if (validateValue({ fldFormat, fldType, min, fldRequires, el: inputEl })) { 
        fieldCmp.setValid();
    } else { 
        fieldCmp.setInvalid();
    }
    if (formCmp) { formCmp.fireEvent('formdatachange'); }
}

export { validateField }