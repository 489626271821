import { chef } from '../../../../../index';
import classNames from '../../../../classNames';
import './styles';

const { templates: { label: labelClassNames } } = classNames;

const getMainStyles= (data = {}) => {
    const { cls, addCls, style, modCls } = data;
    return chef.useCookedStyles({ cls: cls ? cls : labelClassNames.label, modCls, addCls, style, joint: true });
}
const getIcoHtml = (data = {}) => {
    const { icoHtml, icoUrl } = data;
    if (icoHtml) { return `<div class="${labelClassNames.ico}"><div></div><div>${icoHtml}</div></div>`; }
    else if (icoUrl) { return `<div class="${labelClassNames.ico}" style="background-image: url(${icoUrl})"></div>`; }
    else return '';
}
const labelBaseTpl = (data = {}) => {
    const { cmp, id, text } = data;
    const labelCmp = chef.cookCmp({ cmp, id });
    const mainStyles = getMainStyles(data);
    const icoHtml = getIcoHtml(data);

    return `<div ${mainStyles}>
                ${icoHtml}
                <p class="${labelClassNames.text}">${text || ''}</p>
            </div>`
};



export default labelBaseTpl;