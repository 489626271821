export default [
    { label: 'Высоковольтное оборудование', items: ['КРУ', 'КРУЭ', 'КРУН', 'КСО']},
    { label: 'Трансформаторные подстанции и кабельно-проводниковая продукция', items: ['КТП', 'КТПНУ', 'БКТП', 'Высоковольтные выключатели']},
    { label: 'Щиты, шкафы и управление' , items: ['РЗА', 'ПА', 'СОПТ', 'Cистемы автоматизации']},
    { label: 'Аварийное и резервное оборудование', items: ['Дизель-генераторные установки']},
    { label: 'Изоляторы и опоры', items: ['Изоляторы (стеклянные, фарфоровые, полимерные)', 'Стойки', 'Фундаменты']},
    { label: 'Коммуникации и сигнализация', items: ['Систем связи', 'Оптический кабель для ВОЛС', 'Грозотрос']},
    { label: 'Кабеленесущие и монтажные системы', items: ['Кабеленесущие системы']},
    { label: 'Измерения и контроль', items: ['Электроизмерительная аппаратура']},
    { label: 'Освещение', items: ['Светотехническое оборудование']},
    { label: 'Хранилища и резервуары', items: ['Резервуары', 'Подземные ёмкости', 'Резервуарное оборудование']}
]