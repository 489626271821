import { chef, spices } from '../../../../index';
import { setOnActiveCls, isNumber } from '../../../../utils';

const sliderData = {
    blocked: false,
    slideEffect: 'slideX',
    overlappsContainerCmp: null,
    slidesWrapperCmp: null,
    slidesContainerCmp: null,
    pointsCmps: null,
    firstSlideCmp: null,
    lastSlideCmp: null,
    slidesCmps: [],
    slidesCount: 0,
    activeIdx: 0,
    hasSingleSlide: false,
    hasNoSlides: true,
    slideTimeout: 0,
    slideTimeoutDelay: 5000,
    goTimeoutedSlide: function () {},
    setSlidesCmps: function (itms = []) {
        this.slidesCount = itms.length;
        this.slidesCmps = itms;
        this.firstSlideCmp = itms[0];
        this.lastSlideCmp = itms[itms.length-1];
        this.hasSingleSlide = itms.length === 1;
        this.hasNoSlides = !itms.length;
    },
    setActiveIdx: function (idx) {
        this.activeIdx = idx;
    },
    setBlocked: function (blocked) {
        this.blocked = blocked;
    }
}

const goSlide = ({ goToIdx, goNext, goPrev }) => { 
    if (sliderData.blocked) return;
    let { slidesMoverCmp, pointsCmps, slidesCount, activeIdx } = sliderData;
    const slidesMoverEl = slidesMoverCmp.getEl();
    const prevIdx = activeIdx-1;
    const nextIdx = activeIdx+1;
    
    sliderData.goTimeoutedSlide();
    if (goNext) {
        if (activeIdx === slidesCount-1) {
            slidesMoverEl.style.marginLeft = `0`;
            sliderData.setActiveIdx(0);
        } else {
            slidesMoverEl.style.marginLeft = `-${nextIdx*100}%`;
            sliderData.setActiveIdx(nextIdx);
        }
    } else if (goPrev) {
        if (prevIdx < 0) {
            slidesMoverEl.style.marginLeft = `-${(slidesCount-1)*100}%`;
            sliderData.setActiveIdx(slidesCount-1);
        } else {
            slidesMoverEl.style.marginLeft = `-${prevIdx*100}%`;
            sliderData.setActiveIdx(prevIdx);
        }
    } else if (isNumber(goToIdx)) {
        slidesMoverEl.style.marginLeft = `-${goToIdx*100}%`;
        sliderData.setActiveIdx(goToIdx);
    }  

    setOnActiveCls(pointsCmps[sliderData.activeIdx].getEl(), 'ck-active');        
}

const goToSlide = (idx) => goSlide({ goToIdx: idx });

const goNextSlide = () => goSlide({ goNext: true });

const goPrevSlide = () => goSlide({ goPrev: true });

const createSliderData = ({ slides, slideEffect, slideTimeout }) => {
    let pointsHtml = '';
    let slidesHtml = '';
    let slidesCmps = [];
    let pointsCmps = [];
    const slidesCmp = chef.cookCmp();
    const slidesMoverCmp = chef.cookCmp();
    if (slides.length > 1) {
        slides.forEach((it, idx) => { 
            const pointCmp = chef.cookCmp({ handler: { type: 'click', jointDefaults: 'button', fn: () => goToSlide(idx) } });
            const slideCmp = chef.cookCmp();
            const pointCls = `class="ck-point ${idx === 0 ? 'ck-active' : ''}"`;
      
            pointsCmps.push(pointCmp);
            slidesCmps.push(slideCmp);
            slidesHtml += `<div id="${slideCmp.id}" class="ck-slide" >${it.html}</div>`;
            pointsHtml += `<div id="${pointCmp.id}" ${pointCls}><div></div></div>`;     
        });
        sliderData.setSlidesCmps(slidesCmps);
        sliderData.slidesHtml = slidesHtml;
        sliderData.pointsHtml = pointsHtml;
        sliderData.slideEffect = slideEffect;
        sliderData.pointsCmps = pointsCmps;
        sliderData.slidesMoverCmp = slidesMoverCmp;
        sliderData.slidesContainerCmp = slidesCmp;
        sliderData.slideTimeoutDelay = slideTimeout ? isNumber(slideTimeout) ? slideTimeout : 5000 : 0;
    } else if (slides.length) {
        const slideCmp = chef.cookCmp();
        slidesCmps.push(slideCmp);
        sliderData.setSlidesCmps(slidesCmps);
        sliderData.slidesHtml = `<div id="${slideCmp.id}" class="ck-slide" >${slides[0].html}</div>`;  
    }
    return sliderData;
}

const onReadySlider = () => {
    if (sliderData.slidesCount > 1 && sliderData.slideTimeoutDelay) {
        sliderData.goTimeoutedSlide = () => {
            clearTimeout(sliderData.slideTimeout);
            sliderData.slideTimeout = setTimeout(() => { goNextSlide(); }, sliderData.slideTimeoutDelay);
        }
        sliderData.goTimeoutedSlide();
    }
}

export { 
    goToSlide, 
    goNextSlide, 
    goPrevSlide,
    createSliderData,
    onReadySlider
}