import EventsManager from '../eventsManager';
import RouteManager from '../routeManager';
import StorageManager from '../storageManager';
import { windowTpl, windowAlertTpl } from '../theme/templates/default/windows/';

import { 
    ComponentBase, 
    ComponentWindow, 
    ComponentForm, 
    ComponentField, 
    ComponentButton,
    ComponentTitle,
    ComponentExpander,
    ComponentPanel
} from '../components';
import { 
    isArray,
    isNotArray, 
    isObject, 
    isString,
    combineCmpStyle,
    combineCmpCls,
    scrollToEl,
    scrollUp,
    createElementFromHtml
} from '../utils';
import classNames from '../theme/classNames'
import { 
    createHandler,
    createHandlers
} from '../handlers';

class ControlManager {
    rootElement = null;
    components = {};
    globalComponents = {};
    increasedIdNumb = 1;
    lastId = '';
    isLoafing = true;
    extraControllers = {}
    /** */ 
    createNewComponent = (data = {}) => {
        let { type, id } = data;
        type = type ? type : 'default';
        const Component = {
                default: ComponentBase,
                window: ComponentWindow,
                form: ComponentForm,
                field: ComponentField,
                button: ComponentButton,
                title: ComponentTitle,
                expander: ComponentExpander,
                panel: ComponentPanel
              }[type];
    
        if (Component) { 
            return new Component({ ...data, id });
        } else {
            this.log({ label: 'Invalid component type:', info: type, type: 'warn' }); 
            return new ComponentBase({ ...data, id });
        } 
    }
    /** */
    setRootElement = function (rootElement) {
        this.rootElement = rootElement;
        this.routeManager.setRootElement(rootElement);
        this.eventsManager.setRootElement(rootElement);
    }
    /** */
    setInitialDataModel =  function (initialData) {
        this.storageManager.setAll(initialData);
    }
    /** */
    setInitialPages = function (pages = {}) {
        this.routeManager.setPages(pages);
    }
    getPages = function () {
        return this.routeManager.getPages();
    }
    /** */ 
    setInitialExtraControllers = function (controllers) {
        this.extraControllers = controllers;
    }
    /** */
    cookId = function (id) {
        if (id) { this.lastId = id; } else {
            this.increasedIdNumb++;  
            this.lastId = 'ck-'+ this.increasedIdNumb;
        }
        return this.lastId
    }
    /** */
    cookGlobalHandler = function (handler = {}) {
        const { id, type, fn, trottle } = handler;
        this.eventsManager.addGlobalHandler({ id, type, fn, trottle });
    }
    /** */
    cookGlobalHandlers = function (handlers) {
        if (isArray(handlers)) {

        } else if (isObject(handlers)) {
            const { id, type, fn, trottle } = handlers;
            this.eventsManager.addGlobalHandler({ id, type, fn, trottle });
        }
        this.eventsManager.addGlobalHandler({ id, type, fn, trottle });
    }
    /** */
    cookHandler = function (data = {}) { 
        return createHandler({ ...data, ctrl: this });
    }
    /** */
    cookHandlers = function (data = {}) {
        return createHandlers({ ...data, ctrl: this });
    }
    /** */
    queryEl = function (query) {
        return document.querySelector(query);
    }
    queryAll = function (query) {
        return document.querySelectorAll(query);
    }
    /** */
    collectCmp = function (cmp) {
        this.components[cmp.id] = cmp;
    }
    getCmp = function (id) {
        return this.components[id];
    }
    /** */
    cookCmp = function (initData = {}) {
        let { el, html, data, type, handler, handlers } = initData; 
        const id = initData.id ? initData.id : this.cookId();  
        const alreadyCreatedCmp = this.getCmp(id);
        const cmp = alreadyCreatedCmp ? alreadyCreatedCmp : this.createNewComponent({ ...initData, id, type });
 
        cmp.html = html;
        cmp.data = data; 
        if (el) { el.id = id; cmp.el = el; }
        if (handler || handlers) { this.cookHandlers({ handler, handlers, cmp }); }
        this.collectCmp(cmp);
        return cmp;
    }
    /** */
    cookEl = function (initData = {}) {
        let { type, html, id, classList } = initData;
        if (type) {
            const el = document.createElement(type);
            if (classList) {
                if (isArray(classList)) {
                    classList.forEach((cls) => el.classList.add(cls));
                } else if (isString(classList)) {
                    el.classList.add(classList);
                }
            }
            if (id) el.id = id;
            if (html) el.innerHTML = html;
            return el;
        } else return null;
    }
    /** */
    cookParsedPrerenderedEl = function (html) {  
        let temporarryEl = document.createElement('div');
        let createdEl;
        this.preCookedElsHolder = this.preCookedElsHolder || document.createElement('div');
        temporarryEl.innerHTML = html;
        createdEl = temporarryEl.firstChild;
        this.preCookedElsHolder.appendChild(createdEl);
        temporarryEl.remove();
        return createdEl;
    }
    /** */
    useCookedStyles =  function (data = {}) {
        let { mainCls, cls, addCls, modCls, style, size, joint } = data;
        let resCls = combineCmpCls({ mainCls, cls, addCls, modCls }), 
            resStyle = combineCmpStyle({ style, size });

        return joint ? `${resCls} ${resStyle}` : [ resCls, resStyle ];
    }
    bind = function (key, fn, setts) { 
        this.storageManager.bind(key, fn, setts);
    }
    /** */
    set = function (key, val) {
        this.storageManager.set(key, val);
    }
    /** */
    get = function (key) {
        return this.storageManager.get(key);
    }
    /** */
    removeHandler = function ({ id, type }) {
        this.eventsManager.removeHandler({ id, type });
    }
    /** */
    removeHandlers = function ({ cmp, id, type }) {
        this.eventsManager.removeHandlers({ id: cmp.id || id });
    }
    /** */
    splitEls = function (els = []) {
        if (isNotArray(els)) { return els; } else {
            return els.reduceRight( (html, el, idx) => { 
                html += el instanceof String ? el : el();
                return html;
            }, ``);
        }
    }
    /** */
    scrollUp = () => scrollUp();
    scrollTo = ({ el, queryEl }) => {
        const toEl = el ? el : queryEl ? this.queryEl(queryEl) : null;
        scrollToEl(toEl);
    }
    /** */
    goTo = (data = {}) => {
        const { url, link, blankPage } = data;
        if (blankPage) { window.open(url || link); }
        else { window.location.replace(url || link); }
    }
    /** */
    goToHome = (blankPage) => { 
        this.goTo({ url: 'home', blankPage });
    }
    /** */
    relinkEls = async function () {
        const cmps = this.components; 
        for (let key in cmps) {
            const cmp = cmps[key];
            if (!cmp.el) { cmp.linkEl({ el: this.rootElement.querySelector(`#${cmp.id}`) });}
        }
    }
    /** */
    getEventsManager = function () {
        return this.eventsManager;
    }
    /** */ 
    getClsNamesMap = (group) => { /** templates, events, colors */
        if (group) { return classNames[group]; }
        else { return classNames;}
    }
    /** WTF!? */ 
    preCookWindowCmp = function (initData = {}) {
        const { id, win } = initData;
        const createdCmp = this.getCmp(id);  
        const winCmp = createdCmp ? createdCmp : new Component({ id, type: 'window', rootElement: this.rootElement });
        const outerMaskEl = this.cookEl({ type: 'div', classList: 'ck-mask' });
        const winEl = this.cookParsedPrerenderedEl( win({ cmp: winCmp }) );

        winCmp.outerMaskEl = outerMaskEl;
        winCmp.el = winEl;
        this.collectCmp(winCmp);
        return winCmp;
    }
    /** */ 
    shout = function ({ title, message, showTime }) {
        let winCmp = this.getGlobalCmp({ globalType: 'alert' });
        if (winCmp) { 
            winCmp.setTitleText(title)
            winCmp.setContentHtml(message)
            winCmp.show(); 
        } else {
            winCmp = this.cookCmp({ type: 'window' });
            const el = createElementFromHtml( 
                windowAlertTpl({ cmp: winCmp, title, contentHtml: message, autoShow: true, showTime }) 
            );
            this.addGlobalCmp({ globalType: 'alert', cmp: winCmp });
            this.rootElement.appendChild(el);
        }
    }
    /** */ 
    log =  function ({ label, info, type }) {
        if (type === 'warn') {
            console.warn({
                warn: label,
                info: info
            });
        } else if (type === 'error') {
            console.error({
                error: label,
                info: info
            });
        } else {
            console.log({
                log: label,
                info: info
            });
        }
    }
    /** */
    addGlobalCmp = function ({ globalType, cmp }) {
        this.globalComponents[globalType] = cmp;
    }
    getGlobalCmp = function ({ globalType }) {
        return this.globalComponents[globalType];
    }
    /** */ 
    getRouteManager = function () {
        return this.routeManager;
    }
    /** */ 
    getStorageManager = function () {
        return this.storageManager;
    }
    /** */ 
    getEventsManager = function () {
        return this.eventsManager;
    }
    /** */ 
    getPageLabel = function () {
        return this.routeManager.getActivePageLabel();
    }
    getMainPages = function () {
        return this.routeManager.getMainPages();
    }
    getExtraPages = function () {
        return this.routeManager.getExtraPages();
    }
    setTheme = function (theme = {}) {
        this.theme = theme;
    }
    /** */
    init = function () {
        this.routeManager.init();
        this.storageManager.init();
        this.eventsManager.init();
        this.cookGlobalHandler({ type: 'domupdate', fn: () => { this.relinkEls(); } });
        this.cookGlobalHandler({ type: 'windowload', fn: () => { this.storageManager.set('pageLoading', false); } });
        this.isLoafing = false;
    }
    /** */
    constructor ({ eventsManager, routeManager, storageManager }) { 
        this.routeManager = routeManager;
        this.storageManager = storageManager;
        this.eventsManager = eventsManager;
    }
}

const controlManager = new ControlManager({
    eventsManager: new EventsManager(),
    routeManager: new RouteManager(),
    storageManager: new StorageManager()
});  

export default controlManager;

