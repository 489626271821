import { recipe } from '../../../../../index';
import buttonIcoBase from './base';

const buttonIcoTpl = (initData = {}) => {
    return buttonIcoBase({ ...initData })
}

const buttonIcoBurgerTpl = (initData = {}) => {
    return buttonIcoBase({ ...initData, size: 30, icoHtml: recipe.iconsHtml.icoBurger, addCls: initData.addCls || '', modCls: 'ck-button-ico-burger' });
}

const buttonIcoRightArrowTpl = (initData = {}) => {
    return buttonIcoBase({ ...initData, icoHtml: recipe.iconsHtml.icoRight, addCls: initData.addCls || '', modCls: 'ck-button-ico-right-arrow' });
}

const buttonIcoLeftArrowTpl = (initData = {}) => {
    return buttonIcoBase({ ...initData, icoHtml: recipe.iconsHtml.icoLeft, addCls: initData.addCls || '', modCls: 'ck-button-ico-left-arrow' });
}

const buttonIcoCloseTpl = (initData = {}) => {
    return buttonIcoBase({ ...initData, icoHtml: recipe.iconsHtml.icoClose, addCls: initData.addCls || '', modCls: 'ck-button-ico-close' });
}

const buttonIcoWhatsappTpl = (initData = {}) => {
    return buttonIcoBase({ ...initData, icoHtml: recipe.iconsHtml.icoWhatsapp, addCls: initData.addCls || '',  modCls: 'ck-button-ico-whatsapp' });
}

const buttonIcoDownTpl = (initData = {}) => {
    return buttonIcoBase({ ...initData, icoHtml: recipe.iconsHtml.icoDown, addCls: initData.addCls || '',  modCls: 'ck-button-ico-down' });
}

const buttonIcoUpTpl = (initData = {}) => {
    return buttonIcoBase({ ...initData, icoHtml: recipe.iconsHtml.icoUp, addCls: initData.addCls || '', modCls: 'ck-button-ico-up' });
}
export { 
    buttonIcoTpl,
    buttonIcoBurgerTpl,
    buttonIcoRightArrowTpl,
    buttonIcoLeftArrowTpl,
    buttonIcoCloseTpl,
    buttonIcoWhatsappTpl,
    buttonIcoUpTpl,
    buttonIcoDownTpl
}