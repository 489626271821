import ComponentBase from './base';
import classNames from '../theme/classNames';

class ComponentButton extends ComponentBase {
    constructor ({   
        /** Base component prams */       
        id,
        el,
        html,
        handler,
        handlers,
        destroyWithEl,
        childCmps,
        keepedCmps,
        data,
        onReadyFns,
        type,
        rootElement,
        disabled,
        initConfig,
        /** Button component prams */  
        role
    }) {
        super ({        
            id,
            el,
            html,
            handler,
            handlers,
            destroyWithEl,
            childCmps,
            keepedCmps,
            data,
            onReadyFns,
            type,
            rootElement,
            disabled,
            initConfig,
        });
        this.role = role ? role : '';
    }
    setRole = function (role) {
        this.role = role ? role : '';
    }
    getRole = function () {
        return this.role;
    }
    setText = function (text) {
        const { templates: { button: buttonClassNames } } = classNames;
        const titleEl = this.getEl();
        const textEl = titleEl ? titleEl.querySelector(`.${buttonClassNames.text}`) : null;
        if (textEl) { textEl.textContent = text; }
    }
    setIco = function ({ icoHtml, icoUrl }) {
        const { templates: { button }} = classNames;
        const icoEl = this.queryEl(`.${button.ico}`);
        if (icoEl) {
            if (icoHtml) { 
                icoEl.innerHTML = icoHtml; 
            } else if (icoUrl) { 
                icoEl.style.backgroundImage = `url(${icoUrl})`;
            }
        }
    }
    setIcoHtml = function (icoHtml) {
        this.setIco({ icoHtml });
    }
    setIcoUrl = function (icoUrl) {
        this.setIco({ icoUrl });
    }
}

export default ComponentButton;