import { chef, dishes, spices, recipe, cookbook } from 'cookery';
import { logoHeader } from '../logo';
import './styles';

const { useTrottled, joggOnObject } = spices;
const { buttonTpl, buttonIcoBurgerTpl } = dishes;
const { iconsHtml } = recipe;

const header = () => {
    const headerCmp = chef.cookCmp();
    const menuLinksCmp = chef.cookCmp();
    const btnBurgerCmp = chef.cookCmp();
    let menuLinksHtml = '';
    const controllMenuType = () => {
        const winWidth = window.innerWidth;
        if (winWidth <= 700) {
            cookbook.set('mainMenu.minimized', true );
        } else {
            const viewWidth = menuLinksCmp.getWidth();
            const scrollWidth = menuLinksCmp.getElsWidth();
            cookbook.set('mainMenu.minimized', viewWidth < scrollWidth );
        }
    };
    const showWindowFeedback = () => chef.set('windowFeedbackShow', true);
    const showMenuModal = () => chef.set('menuModalShow', true);
    
    joggOnObject((chef.getMainPages()), (key, it) => {
        const cmp = chef.cookHandler({ type: 'click', defaultHandlers: { defaultsFor: 'button' }, fn: () => chef.goTo({ url: key }) });
        menuLinksHtml += `<a id="${cmp.id}" class="${it.active ? 'active' : ''}">${it.name}<div class="underline"></div></a>`;
    });
    chef.bind('mainMenu.minimized', (vl) => {  
        if (vl) { headerCmp.addCls('minimized'); } else { headerCmp.removeCls('minimized'); }
    });
    chef.cookHandlers({
        cmp: menuLinksCmp,
        handlers: [
            { type: 'render', delay: 20, fn: controllMenuType },
            { type: 'windowresize', delay: 100, fn: controllMenuType }
        ]
    });
    return (
        `<div id="${headerCmp.id}" class="ck-header">
          <div class="ck-header-content">
            ${logoHeader()}
            <div id="${menuLinksCmp.id}" class="ck-hedaer-menu-links">${menuLinksHtml}</div>
            ${buttonTpl({ 
                text: 'Обратная связь', 
                addCls: 'ck-button-feedback', 
                handler: { type: 'click', fn: showWindowFeedback }
            })}
            ${buttonIcoBurgerTpl({ id: btnBurgerCmp.id, addCls : 'ck-button-ico-burger', handler: { type: 'click', fn: showMenuModal } })}
          </div>
        </div>`
    );
}

export default header;