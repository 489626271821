import { chef } from '../../../../../index';
import { isString, isArray } from '../../../../../utils';
import classNames from '../../../../classNames';
import './styles';

const { templates: { titleWindow: titleClassNames }} = classNames;

const getTools = (tools) => {
    if (isString(tools)) {
        return `<div class="${titleClassNames.tools}">${tools}</div>`
    } else if (isArray(tools)) {
        return tools.reduce((html, it) => {
           html += it; return html;
        }, '');
    } else return '';
}

const titleWindowTpl = (initData = {}) => {
    let { cmp, id, text, align, color, tools } = initData;
    id = cmp ? cmp.id : id ? id : chef.cookId();
    const alignCls = { right: 'ck-align-right', center: 'ck-align-center' }[align] || ''; /* Align left is default */
    const colorCls = chef.getClsNamesMap('colors')[color] || ''; /* Main color is default */
    const toolsHtml = getTools(tools);

    return `<div class="${titleClassNames.title} ${alignCls} ${colorCls}">
             <p class="${titleClassNames.text}">${text ? text : '...'}</p>
             ${toolsHtml}
           </div>`
}

export { titleWindowTpl }