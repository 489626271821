import expanderTpl from './base';

const expanderModATpl = (data = {}) => {
     return expanderTpl({ ...data, modCls: 'ck-mod-a'});
}

const expanderModBTpl = (data = {}) => {
     return expanderTpl({ ...data, modCls: 'ck-mod-b'});
}

const expanderModCTpl = (data = {}) => {
     return expanderTpl({ ...data, modCls: 'ck-mod-c'});
}

export { 
     expanderTpl, 
     expanderModATpl, 
     expanderModBTpl,
     expanderModCTpl
}