import { fieldTextTpl } from './text';
import { fieldTextareaTpl } from './textarea';
import { fieldComboTpl } from './combo';
import { fieldRadioGroupTpl } from './radiogroup';
import { fieldCheckBoxTpl } from './checkbox';

export {
    fieldTextTpl,
    fieldTextareaTpl,
    fieldComboTpl,
    fieldRadioGroupTpl,
    fieldCheckBoxTpl    
}