 import { 
    sectionTpl,
    panelTpl
} from './containers';
import { sliderTpl } from './sliders';
import { footerTpl, footerModATpl } from './footer';
import { headerTpl, headerMainTpl } from './headers';
import { 
    buttonTpl,
    buttonLinedTpl,
    buttonLinedSoftTpl,
    buttonRoundedTpl,
    buttonLinedRoundedTpl,
    buttonLinedSoftRoundedTpl,
    buttonCheckboxTpl,
    buttonRadioTpl,
    buttonLinkTpl,
    buttonLinkSoftTpl,
    buttonIcoTpl,
    buttonIcoBurgerTpl,
    buttonIcoRightArrowTpl,
    buttonIcoLeftArrowTpl,
    buttonIcoCloseTpl,
    buttonIcoWhatsappTpl,
    buttonIcoUpTpl,
    buttonIcoDownTpl,
    buttonSpecialAnimated1Tpl,
    buttonSpecialAnimated2Tpl
} from './buttons';
import { 
    expanderTpl,
    expanderModATpl, 
    expanderModBTpl,
    expanderModCTpl 
} from './expanders';
import {
    labelTpl,
    labelAlertTpl,
    labelLinkTpl
} from './labels';
import {
    titleSectionTpl,
    titleSectionModATpl,
    titleWindowTpl
} from './titles';
import {
        fieldInputTpl,
        fieldComboTpl,
        fieldTextareaTpl,
        fieldCheckBoxTpl
} from './fields';
import { maskTpl, infoCardTpl, etcListTpl} from './other';
import { windowTpl } from './windows';
import { formTpl } from './forms';
import { menuModalTpl } from './menus';
import { canvasImgAnimatedTpl } from './specials';

const templates = {
    /** Containers */
    sectionTpl,
    panelTpl,
    /** Sliders */
    sliderTpl,
    /** Main */
    footerTpl,
    footerModATpl,
    headerMainTpl,
    /** Buttons */
    buttonTpl,
    buttonLinedTpl,
    buttonLinedSoftTpl,
    buttonRoundedTpl,
    buttonLinedRoundedTpl,
    buttonLinedSoftRoundedTpl,
    buttonCheckboxTpl,
    buttonRadioTpl,
    buttonLinkTpl,
    buttonLinkSoftTpl,
    /** Ico buttons */
    buttonIcoTpl,
    buttonIcoBurgerTpl,
    buttonIcoRightArrowTpl,
    buttonIcoLeftArrowTpl,
    buttonIcoCloseTpl,
    buttonIcoWhatsappTpl,
    buttonIcoUpTpl,
    buttonIcoDownTpl,
    /** Special buttons */
    buttonSpecialAnimated1Tpl,
    buttonSpecialAnimated2Tpl,
    /** Expanders */
    expanderTpl,
    expanderModATpl, 
    expanderModBTpl,
    expanderModCTpl,
    /** Labels */
    labelTpl,
    labelAlertTpl,
    labelLinkTpl,
    /** Titles, Headers */
    headerTpl,
    titleSectionTpl,
    titleSectionModATpl,
    titleWindowTpl,
    /** Fields */
    fieldInputTpl,
    fieldComboTpl,
    fieldTextareaTpl,
    fieldCheckBoxTpl,
    /** Windows */
    windowTpl,
    /** Other */
    maskTpl,
    infoCardTpl,
    formTpl,
    menuModalTpl,
    etcListTpl,
    /** Special */
    canvasImgAnimatedTpl
}

export default templates;