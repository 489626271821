import controlManager from './controlManager';
import * as utils from './utils';
import theme from './theme';

const chef = controlManager;   
const dishes = theme.templates;  
const spices = utils;
const recipe = theme;
const cookbook = controlManager.getStorageManager();
const beginCook = ({ rootElement, initialDataModel, initialPages, initialExtraControllers, initialTheme }) => {
   if (chef.isLoafing) {
      chef.setRootElement(rootElement);
      chef.setInitialDataModel(initialDataModel);
      chef.setInitialPages(initialPages);
      chef.setInitialExtraControllers(initialExtraControllers);
      chef.setTheme(initialTheme);
      chef.init();
   }
}

export {
   chef,   /** Main controller */
   dishes, /** Component templates */
   spices, /** Utils */
   recipe,  /** Theme data (images, styles, class etc.) */
   cookbook,
   beginCook
}