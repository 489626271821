import main from './main';
import production from './production';
import contacts from './contacts';
import privacyPolicy from './privacyPolicy';

export {
    main,
    production,
    contacts,
    privacyPolicy
}