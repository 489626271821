import { 
    buttonTpl, 
    buttonLinedTpl,
    buttonLinedSoftTpl,
    buttonRoundedTpl,
    buttonLinedRoundedTpl,
    buttonLinedSoftRoundedTpl,
    buttonSpecialAnimated1Tpl,
    buttonSpecialAnimated2Tpl
} from './button';
import {
    buttonIcoTpl,
    buttonIcoBurgerTpl,
    buttonIcoRightArrowTpl,
    buttonIcoLeftArrowTpl,
    buttonIcoCloseTpl,
    buttonIcoWhatsappTpl,
    buttonIcoUpTpl,
    buttonIcoDownTpl
} from './ico';
import { buttonCheckboxTpl } from './checkbox';
import { buttonRadioTpl } from './radio';
import { 
    buttonLinkTpl, 
    buttonLinkSoftTpl 
} from './link';

export {
    buttonTpl,
    buttonLinedTpl,
    buttonLinedSoftTpl,
    buttonRoundedTpl,
    buttonLinedRoundedTpl,
    buttonLinedSoftRoundedTpl,
    buttonSpecialAnimated1Tpl,
    buttonSpecialAnimated2Tpl,

    buttonIcoTpl,
    buttonIcoBurgerTpl,
    buttonIcoRightArrowTpl,
    buttonIcoLeftArrowTpl,
    buttonIcoCloseTpl,
    buttonIcoWhatsappTpl,
    buttonIcoUpTpl,
    buttonIcoDownTpl, 

    buttonCheckboxTpl,
    buttonRadioTpl,
    buttonLinkTpl,
    buttonLinkSoftTpl
}