import { 
    isArray,
    isObject,
    joggOnArray,
    joggOnObject,
    useTrottled,
    setOnPressCls,
    setOnMouseenterCls,
    setOnMouseleaveCls,
    setOnFocusCls,
    setOnBlurCls,
    setOnShownScrolledCls,
    setOnScrollInYCls,
    setOnScrollOutYCls 
} from '../utils';
/** */
const getArrayed = (val) => {
    if (val) { return isArray(val) ? val : val ? [val] : []; } 
    else { return []; }
}
/** */
const getDefaultHandlersForButton = () => ({
    click: { type: 'click', fn: (e) => { e.stopPropagation(); setOnPressCls(e.currentTarget || e.target); }},
    mouseenter: { type: 'mouseenter', fn: (e) => { e.stopPropagation(); setOnMouseenterCls(e.currentTarget|| e.target); }},
    mouseleave: { type: 'mouseleave', fn: (e) => { e.stopPropagation(); setOnMouseleaveCls(e.currentTarget|| e.target); }}
});
/** */
const getDefaultHandlersForFieldInput = () => ({
    focus: { type: 'focus', fn: (e) => { 
        const el = e.currentTarget;
        setOnFocusCls(el);
        setOnPressCls(el);
    }},
    blur: { type: 'blur', fn: (e) => setOnBlurCls(e.currentTarget) }
});
/** */
const getDefaultHandlersForScrolled = (data) => {
    let { cmp, trottle, scrollTrottle, offsets, scrollOffsets  } = data; 
    const useTrottle = trottle || scrollTrottle || 100;
    const useOffsets = offsets || scrollOffsets;
    const scrollInYFn = async () => {
        const winHeight = window.innerHeight;
        if (cmp && cmp.alive) {
            const el = cmp.getEl();
            const elRect = el.getBoundingClientRect();
            const elRectTop = useOffsets ? elRect.top + scrollOffsets.top : elRect.top;
            const elRectBottom = useOffsets ? elRect.bottom + scrollOffsets.bottom : elRect.bottom;
            const elInVisibleArea = (elRectTop < 0 && elRectBottom > 0) || (elRectTop > 0 && elRectTop <= winHeight);

            if (elInVisibleArea) {
                setOnShownScrolledCls(el);
                setOnScrollInYCls(el);   
            } else {
                setOnScrollOutYCls(el); 
            }
        }     
    };

    return [ 
        { type: 'pagescroll', fn: useTrottled(() => { scrollInYFn() }, useTrottle ) },
        { type: 'render', fn: () => { scrollInYFn()} }
    ];
};
/** */
const getDefaultHandlers = (data = {}) => {
    let { defaultsFor, includeHandlers, excludeTypes } = data;
    const includeHandlersArr = getArrayed(includeHandlers);
    const excludesTypesArr = getArrayed(excludeTypes);
    const defaultHandlersObj = function () {
        const defaultsForArr = getArrayed(defaultsFor);
        const obj = {
            button: getDefaultHandlersForButton(data),
            fieldinput: getDefaultHandlersForFieldInput(data),
            scrolled: getDefaultHandlersForScrolled(data),
        };
        let resObj = {};
        joggOnArray(defaultsForArr, (it) => {
            if (obj[it]) {
                resObj = Object.assign(resObj, obj[it]);
            }
        });
        return resObj
    }()
    const collectedHandlers = [];

    if (excludesTypesArr.length) {
        joggOnArray(excludesTypesArr, (it) => {
            if (defaultHandlersObj[it]) { 
                defaultHandlersObj[it].dontuse = true;
            }
        });
    }
    if (includeHandlersArr.length) {
        joggOnArray(includeHandlersArr, (it) => {
            const defHnd = defaultHandlersObj[it.type];
            if (defHnd && !defHnd.dontuse) {
                defHnd.dontuse = true;
                collectedHandlers.push({ 
                    type: it.type, 
                    delay: it.delay,
                    fn: (data) => { defHnd.fn(data); it.fn(data); }
                });
            } else { collectedHandlers.push(it); }
        });
    }
    joggOnObject(defaultHandlersObj, (key, it) => {
        if (!it.dontuse) { collectedHandlers.push(it); }
    });
    return collectedHandlers;
}

export { getDefaultHandlers }