import { chef } from '../../../../index';
import { isObject } from '../../../../utils';
import { icoLeft, icoRight } from '../../../../theme/images/icons/html';
import { buttonIco } from '../buttons';
import { goNextSlide, goPrevSlide, createSliderData, onReadySlider } from './control';
import './styles';

const getMainStyles = (data = {}) => {
    const { cls, addCls, style, slideEffect , hasSingleSlide} = data;
    return chef.useCookedStyles({ cls: cls ? cls : `ck-slider ${hasSingleSlide ? 'ck-single-slide' : ''} ${slideEffect === 'slideX' ? 'slide-x' : 'slide-y'}`, addCls, style, joint: true });
}
const getShowControls = (data = {}) => {
    const { controls } = data;
    let showPoins = controls ? isObject(controls) ? controls.showPoints : true : false;
    let showArrows = controls ? isObject(controls) ? controls.showArrows : true : false;
    return { showPoins, showArrows };
}

const sliderTpl = (initData = {}) => {
    let { cmp, id, slides, slideEffect, slideTimeout } = initData;
    const sliderCmp = cmp ? cmp : chef.cookCmp({ id }); 
    let { showPoins, showArrows } = getShowControls(initData);
    let nextBtnId = '';
    let prevBtnId = '';
    let slidesContainerId = '';
    let slidesMoverId = '';
    const { slidesContainerCmp, slidesMoverCmp, slidesHtml, pointsHtml, hasNoSlides, hasSingleSlide } = createSliderData({ slides, slideEffect, slideTimeout });
    const mainStyles = getMainStyles({ ...initData, hasSingleSlide});

    if (hasNoSlides || hasSingleSlide) {
       showPoins = false; showArrows = false;
    } else {
        sliderCmp.onReady = onReadySlider;
        slidesContainerId = `id="${slidesContainerCmp.id}"`;
        slidesMoverId = `id="${slidesMoverCmp.id}"`;
        nextBtnId = chef.cookHandler({ type: 'click', jointDefaults: 'button', fn: (e) => { goNextSlide(); } });
        prevBtnId = chef.cookHandler({ type: 'click', jointDefaults: 'button', fn: (e) => { goPrevSlide(); } });
    }
    return (
        `<div id="${sliderCmp.id}" ${mainStyles}">
            <div ${slidesContainerId} class="ck-slides">
                <div ${slidesMoverId} class="ck-slides-mover"></div>
                ${slidesHtml}
            </div>
            <div class="ck-slider-overlapps">
                <div class="ck-overlapp"></div>
                <div class="ck-overlapp"></div>
                <div class="ck-overlapp"></div>
            </div>
            <div class="ck-slider-controls">
                ${ showPoins ? `<div class="ck-slider-controls-points">${pointsHtml}</div>` : '' } 
                ${ showArrows ? buttonIco({ id: prevBtnId, icoHtml: icoLeft, addCls: 'ck-prev' }) : '' }
                ${ showArrows ? buttonIco({ id: nextBtnId, icoHtml: icoRight, addCls: 'ck-next' }) : '' }
            </div>
        </div>`
    );
}

export  { sliderTpl };