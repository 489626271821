import ComponentBase from './base';
import classNames from '../theme/classNames';

class ComponentTitle extends ComponentBase {
    constructor ({        
        id,
        el,
        html,
        handler,
        handlers,
        destroyWithEl,
        childCmps,
        keepedCmps,
        data,
        onReadyFns,
        type,
        rootElement,
        initConfig
    }) {
        super ({        
            id,
            el,
            html,
            handler,
            handlers,
            destroyWithEl,
            childCmps,
            keepedCmps,
            data,
            onReadyFns,
            type,
            rootElement,
            initConfig
        });
    }
    setText = function (text) {
        const { templates: { title: titleClassNames } } = classNames;
        const titleEl = this.getEl();
        const textEl = titleEl ? titleEl.querySelector(`.${titleClassNames.text}`) : null;
        if (textEl) { textEl.textContent = text; }
    }
}

export default ComponentTitle;