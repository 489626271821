import { chef, dishes, spices, recipe, cookbook } from 'cookery';
import './styles';

const { useTrottled, joggOnObject } = spices;
const { buttonTpl, buttonIcoBurgerTpl } = dishes;
const { iconsHtml } = recipe;


const logoHeader = () => {
    const logoCmp = chef.cookHandler({ type: 'click', fn: () => chef.goToHome() });
    return `<div id="${logoCmp.id}" class="ck-logo-header">
               <div class="ck-logo-ico"></div>
               <div class="ck-line-y"></div>
               ${chef.get('logoText')}
           </div>`
}

const logoFooter = () => {
    const logoCmp = chef.cookHandler({ type: 'click', fn: () => chef.goToHome() });
    return `<div id="${logoCmp.id}" class="ck-logo-footer">
               <div class="ck-line-x"></div>
               <div class="ck-line-x"></div>
               ООО «Специализированный Центр Комплектации»
           </div>`
}

export {
    logoHeader,
    logoFooter
}