import { dishes } from 'cookery';
import header from '../../components/header';
import pageTitle from '../../components/pageTitle';
import footer from '../../components/footer';
import menu from '../../components/menu';
import contacts from '../../components/contacts';
import { windowFeedback } from '../../components/windows';
import feedback from '../../components/feedback';

const { sectionTpl } = dishes;

const contactsPage = () => {
    return `
        ${pageTitle({ title: 'Контакты' })}
        ${contacts()}
        ${feedback()}
        ${footer()}
        ${header()}
        ${menu()}
        ${windowFeedback()}
    `;
}

export default contactsPage;