import { chef, dishes, spices } from 'cookery';
import './styles';

const { sectionTpl, infoCardTpl, buttonLinkSoftTpl } = dishes;
const { getFormatedPhoneNumber } = spices;

const contacts = (data = {}) => {
    const mainPhone = chef.get('contacts.phone');
    const mainPhoneFormated = getFormatedPhoneNumber(mainPhone);
    const accPhone = chef.get('contacts.accountingDepatment.phone');
    const accPhoneFormated = getFormatedPhoneNumber(accPhone);
    const mainEmail = chef.get('contacts.email');
    const mainAddress = chef.get('contacts.address');
    const addresMapIframe = chef.get('contacts.addresMapIframe');
    return sectionTpl({ 
        addCls: 'ck-contacts',
        contentHtml: `<div class="ck-contact-cards">
                ${infoCardTpl({
                    title: 'Контакты (общие/отдел продаж):',
                    itemsData: {
                        items: [
                            { name: 'email:', value: `<a href="mailto:${mainEmail}">${mainEmail}</a>` },
                            { name: 'телефон:', value: `<a href="tel:${mainPhone}">${mainPhoneFormated}</a>` },
                            { name: 'whatsapp:', value: `<a href="https://wa.me/${mainPhone}">${buttonLinkSoftTpl({ text: mainPhoneFormated })}</a>` },
                            { name: 'юр. адрес:', value: mainAddress }
                        ]
                    }
                })}
                ${infoCardTpl({
                    title: 'Контакты (бухгалтерия):',
                    itemsData: {
                        items: [
                            { name: 'телефон:', value: `<a href="tel:${accPhone}">${accPhoneFormated}</a>` },
                            { name: 'whatsapp:',  value: `<a href="https://wa.me/${accPhone}">${buttonLinkSoftTpl({ text: accPhoneFormated })}</a>` }
                        ]
                    }
                })}
            </div>
            <div class="ck-contacts-address-map">${addresMapIframe} </div>`  
        });
}

export default contacts;