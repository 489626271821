import { chef } from 'cookery';
import { validateField } from './validators'; 
import './styles';

const getMainStyles = (data = {}) => {
    let { cls, addCls, style, noIndicateBegin } = data;
    return chef.useCookedStyles({ cls: `${cls ? cls : 'ck-field'} ${noIndicateBegin ? 'ck-no-indicate-begin' : ''}`, addCls, style, joint: true });
}
const getInfoHtml = (data = {}) => {
    const { info } = data;
    return info ? `<p class="ck-field-info">${info}</p>` : ''
}
const getIndicatorHtml = (data = {}) => {
    const { fldRequires, format } = data;
    return fldRequires ? `<div class="ck-field-indicator"><div></div></div>` : '';
}
const getLabelHtml = (data = {}) => {
    const { fldLabel } = data;
    return fldLabel ? `<p class="ck-field-label">${fldLabel}</p>` : '';
}

const fieldTpl = (initData = {}) => {
    let { cmp, fldPaceHolder, fldName, fldType } = initData;
    const fieldCmp = cmp ? cmp : chef.cookCmp({...initData, type: 'field' });
    const inputCmp = chef.cookCmp();
    const mainStyles = getMainStyles(initData); 
    const infoHtml = getInfoHtml(initData);
    const indicatorHtml = getIndicatorHtml(initData);
    const labelHtml = getLabelHtml(initData);
    const inputType = fldType || 'text';

    chef.cookHandlers({ 
        ...initData, 
        cmp: inputCmp, 
        defaultHandlers: {
            defaultsFor: 'fieldinput',
            includeHandlers: fldType === 'checkbox' ? [
                { type: 'change', delay: 100, fn: (e) => { validateField({ ...initData, fieldCmp, inputCmp, e });} }
            ] : [
                { type: 'blur', fn: (e) => { validateField({ ...initData, fieldCmp, inputCmp, e }); }},
                { type: 'input', delay: 100, fn: (e) => { validateField({ ...initData, fieldCmp, inputCmp, e });} },
            ]
        } 
    });
    if (fldType === 'checkbox' ) {
        return `<div id="${fieldCmp.id}" ${mainStyles}>
                    <label class="ck-field-input-checkbox-simulator">
                        <input id="${inputCmp.id}" class="ck-field-input" name="${fldName}" type="${inputType}" />
                        <div class="ck-field-checkmark"></div>
                    </label>
                    ${labelHtml} 
               </div>`
    } else {
        return `<div id="${fieldCmp.id}" ${mainStyles}>
                    ${labelHtml}
                    <div class="ck-input-container">
                        <div class="ck-under-input-bground"></div>
                        <input id="${inputCmp.id}" class="ck-field-input" name="${fldName}" placeholder="${fldPaceHolder || ''}" type="${inputType}" />
                        ${indicatorHtml}
                    </div>
                    ${infoHtml}
                </div>`
    }
};

export default fieldTpl;