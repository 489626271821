import { chef } from '../../cookery';
const getDomenFromUrl = (url) => url.replace(/(https?:\/\/)?(www\.)?/, '');
const api = {
    requestFeedback: (data = {}) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            const message = function () {
                return `
                  Имя: ${data.name}
                  <br/>Телефон: ${data.phone || 'Не указано...'}
                  <br/>email: ${data.email || 'Не указано...'}
                  <br/>Сообщение:${data.message || 'Сообщение отсутствует...'}`;
            }();

            formData.set('from', 'administrator@sck77.ru');
            formData.set('subj', `Обратная связь с сайта ${getDomenFromUrl(chef.get('mainUrl'))}`);
            formData.set('to', chef.get('contacts.email'));
            formData.set('msg', message);
            formData.append('submit', 'true');
            grecaptcha.ready(() => {
                grecaptcha.execute(chef.get('recaptchaKey'), { action: 'submit' }).then( (token) =>{
                    formData.set('g-recaptcha-response', token);
                    fetch('/mailrecaptched.php', { method: 'POST', body: formData })
                    .then( (resp) => { return resp.json(); })
                    .then((data) => { resolve(data); })
                    .catch((err) => { reject(err); })
                });
            });
        });
    }
}

export default api;