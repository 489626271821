import { chef, dishes } from 'cookery';
import './styles';

const { sectionTpl, buttonSpecialAnimated2Tpl } = dishes;

const feedback = (data = {}) => {
    const showWindowFeedback = () => chef.set('windowFeedbackShow', true);
    return sectionTpl({
        contentHtml: `<div  class="ck-feedback">
                         <p>Есть вопросы или предложения? Свяжитесь с нами.</p>
                         ${buttonSpecialAnimated2Tpl({ 
                            text: 'Обратная связь', 
                            txtColor: 'white',
                            handler:{ type: 'click' , fn: showWindowFeedback }
                        })}
                     </div>`
    })
}

export default feedback;