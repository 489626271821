import { beginCook } from 'cookery';
import './sass';
import './sass/global';
import {
    main,
    production,
    contacts,
    privacyPolicy
} from './pages';

beginCook({
   rootElement: document.querySelector('#app'),
   initialTheme: {
      colors: {
          main: '#00b9e7',
          second: '#151616'
      }
   },
   initialDataModel: {
        mainUrl: 'https://www.sck.ru',
        copyrightText: 'Все права защищены © 2024',
        logoText: 'Специализированный <br/>Центр Комплектации',
        pageLoading: true, 
        maskModalShow: false,
        menuModalShow: false,
        windowFeedbackShow: false,
        contacts: {
            email: 'info.sck77@gmail.com',
            phone: '+79886044110',
            address: '109147, г. Москва, вн. тер. г. муниципальный округ Таганский, ул. Таганская, д. 31/22, пом. 9/1',
            salesDepartment: { phone: '+79886044110' },
            accountingDepatment: { phone: '+79886044110' },
            addresMapIframe: '<iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A1218f9b1bb6d11b9908c03606427cf04fab13a7c6908b9da78069bc1f45e16db&amp;source=constructor" width="100%" height="440" frameborder="0"></iframe>'
        },
        mainMenu: {
        minimized: false,
        items: [
                { name: 'Главная', url: 'home', active: true },
                { name: 'Наша продукция', url: 'production', active: false },
                { name: 'Контакты', url: 'contacts', active: false }
            ]
        },
        recaptchaKey: '6LfehdUpAAAAAIR7brswnZkMDgDDffoTbY_zBhBd'
    },
    initialPages: {       
        'main': {
            title: 'ООО «СЦК»" - Главная', 
            name: 'Главная',
            content: main,
            default: true,
            active: false,
        },
        'production': {
            title: 'ООО «СЦК»" - Продукция',
            name: 'Наша продукция',
            content: production,
            default: false,
            active: false,
        },
        'contacts': {
            title: 'ООО «СЦК»" - Контакты',
            name: 'Контакты',
            content: contacts,
            default: false,
            active: false,
        },
        'privacy-policy': {
            title: 'ООО «СЦК»" - Политика конфиденциальности',
            name: 'Политика конфиденциальности',
            content: privacyPolicy,
            extra: true
        }
    }
});