import { chef } from '../../../../../index';
import { isString, isObject } from '../../../../../utils';
import { titleWindowTpl } from '../../titles';
import { maskTpl } from '../../other';
import { buttonIcoCloseTpl } from '../../buttons';
import classNames from '../../../../classNames'
import './styles';

const { templates: { windowModal: winClassNames } } = classNames;
const getWrapperStyles = (data = {}) => {
    const { cls, addCls, style } = data;
    return chef.useCookedStyles({ cls: cls ? cls : winClassNames.wrapper, addCls, style, joint: true });
}
const getWinStyles = (data = {}) => {
    let { cls, addCls, style, modCls, rounded, winColor,} = data;
    if (rounded) { modCls += ' ck-window-rounded'; }
    return chef.useCookedStyles({ cls: cls ? cls : `${winClassNames.window} ${modCls}`, addCls, style, joint: true });
}
const getTitleData = (title) => {
    return title ? isString(title) ? { text: title } : isObject(title) ? title : {} : {};
}

const windowModalTpl = (initData = {}) => {
    let { contentHtml, title, binds, autoShow } = initData; 
    const winCmp = chef.cookHandlers({...initData, createCmpType: 'window' });
    const titleCmp = chef.cookCmp({ type: 'title' });
    const contentCmp = chef.cookCmp();
    const footerCmp = chef.cookCmp();
    const showingBindKey = binds && binds.showing;
    const wrapperStyles = getWrapperStyles(initData);
    const winStyles = getWinStyles(initData);
    const titleData = getTitleData(title);
    const onShowHandlerFn = showingBindKey ? () => chef.set(showingBindKey, true ) : () => winCmp.show();
    const onClosekHandlerFn = showingBindKey ? () => chef.set(showingBindKey, false ) : () => winCmp.hide();
    const onClosekHandler = { type: 'click', fn: () => onClosekHandlerFn() };

    winCmp.keepTitleCmp(titleCmp);
    winCmp.keepContentCmp(contentCmp);
    if (autoShow) { chef.cookHandler({ cmp: winCmp, type: 'render', fn: onShowHandlerFn }); }
    return `<div id="${winCmp.id}" ${wrapperStyles}>
              ${maskTpl({ opacity: 0.7, addCls: winClassNames.mainMask, handler: onClosekHandler })}
              <div ${winStyles}>
                <div class="ck-window-background"></div>
                <div class="${winClassNames.header}">
                    ${titleWindowTpl({ 
                        ...titleData, 
                        cmp: titleCmp,
                        tools: buttonIcoCloseTpl({ addCls: 'ck-button-close', handler: onClosekHandler }) 
                    })}                     
                </div>
                <div id="${contentCmp.id}" class="${winClassNames.content}">${contentHtml ? contentHtml : ''}</div>
                <div id="${footerCmp.id}" class="${winClassNames.footer}"></div>
              </div>
            </div>`
}

export default windowModalTpl;