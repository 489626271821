import { useTrottled, joggOnArray, joggOnObject } from "../utils";

class EventsManager {
    handlers = {};
    syntheticHandlers = {};
    globalHandlers = {
        domupdate: [],
        windowresize: [],
        windowload: [],
        pagescroll: []
    };
    updateHandlers = (data) => {
        let { cmp, id, type, target, fn, toHandlers } = data;
        let idIandlers = toHandlers[id]; 
        let typeHandlers = null;
        let typeHndlerFns =  null;
        let handler = { id, target, type, fn };

        if (idIandlers) { 
            typeHandlers = idIandlers.find((it) => it.type === type );
            if (typeHandlers) {
                typeHndlerFns = typeHandlers.fns;
                if (typeHndlerFns) { typeHndlerFns.push(fn); }
                else { typeHandlers.fns = [ fn ]; }
            } else {
                idIandlers.push({ type, fns: [fn], cmp });
            }  
        } else { toHandlers[id] = [{ type, fns: [fn], cmp }]; }
        return handler;
    }
    /** */
    addSyntheticHandler = (data = {}) => {
        let { cmp, type, fn, delay } = data;
        if (cmp) { 
            cmp.eventsConnector[type]({ fn: () => fn(cmp), delay });
            return this.updateHandlers({ ...data, id: cmp.id, toHandlers: this.syntheticHandlers });
        } else { return null; }
    }
    /** */
    addHandler = function (data = {}) {
        return this.updateHandlers({ ...data, toHandlers: this.handlers });
    }
    /** */
    addHandlers = function ({ id, handlers }) {
        const collectedHandlers = [];
        if (handlers) {
            joggOnArray(handlers, (it) => {
                const handler = { ...it, id }
                collectedHandlers.push(handler)
                this.addHandler(handler);
            });        
        }
        return collectedHandlers;
    }
    /** */
    addGlobalHandler = ({ id, type, fn, trottle }) => {
        const trottledFn = trottle ? useTrottled(fn, trottle) : null;
        this.globalHandlers[type].push({ id, fn: trottledFn ? trottledFn : fn });
    }
    /** */
    removeHandler = function ({ id, type }) {
        let idHandlers = this.handlers[id]
        idHandlers = idHandlers.filter((it) => it.type !== type );
    }
    /** */
    removeHandlersByType = function ({ id, type }) {
        let idHandlers = this.handlers[id]
        idHandlers = idHandlers.filter((it) => it.type !== type );
    }
    /** */
    removeHandlersById = function () {}
    /** */
    clearHandlers = function () {}
    /** */
    relinkHandlers = function () {
         const handlers = this.handlers;
         joggOnObject(handlers, (key, val) => {
            let idHandlers = val;
            let el = this.rootElement.querySelector(`#${key}`);
            
            if (el && idHandlers) {
                joggOnArray(idHandlers, (it) => {
                    let fn = (e) => {
                            if (it.fns)  { 
                                joggOnArray(it.fns, (it, idx) => {
                                    e.stopPropagation(); 
                                    it(e);  
                                }); 
                            }
                        };
                    if (el.ckEventHandlers) {
                        el.removeEventListener(it.type, el.ckEventHandlers[it.type]);
                        el.ckEventHandlers[it.type] = fn;
                    } else {
                        el.ckEventHandlers = { [it.type]: fn }
                    }
                    el.addEventListener(it.type, fn)
                });
            }
         });
    }
    /** */
    relinkComponents = async function () {
        const handlers = this.handlers;
        joggOnObject(handlers, (key, val) => {
            let idHandlers = val;
            let el = this.rootElement.querySelector(`#${key}`);
            if (el && idHandlers) {
                joggOnArray(idHandlers, (it, idx) => {
                    el.addEventListener(it.type, (e) => {
                        if (it.fns)  { 
                            joggOnArray(it.fns, (it, idx) => { it(e); })
                        }
                    });
                });
            }
        });
    }
    /** */
    onWindowResizeEvent = function () {
        window.addEventListener('resize', () => {
            joggOnArray(this.globalHandlers.windowresize, (it) => it.fn() );
        });
    }
     /** */
    onPageLoadEvent = function () {
        const globalHandlers = this.globalHandlers;
        window.onload = function (e) { 
            joggOnArray(globalHandlers.windowload, (it) => it.fn() );
        };
    }
    /** */
    onPageScrollEvent = function () {
        const globalHandlers = this.globalHandlers;
        document.querySelector('body').onscroll = function (e) {
            joggOnArray(globalHandlers.pagescroll, (it) => it.fn() );
        };
    }
    /** */
    setRootElement = function (rootElement) {
        this.rootElement = rootElement;
    }
    /** */
    init = function () {
        const globalHandlers = this.globalHandlers;
        const observer = new MutationObserver((data, obs) => {
                  joggOnArray(globalHandlers.domupdate, (it) => it.fn() );
              });
              
        observer.observe( document.body, { childList: true, subtree: true });
        this.addGlobalHandler({ type: 'domupdate', fn: () => this.relinkHandlers() });
        this.onPageLoadEvent();
        this.onWindowResizeEvent(); 
        this.onPageScrollEvent();
    }
    /** */
    constructor () {}
}

export default EventsManager;