import { dishes, recipe, cookbook } from 'cookery';
import header from '../../components/header';
import pageTitle from '../../components/pageTitle';
import footer from '../../components/footer';
import menu from '../../components/menu';
import privacyPolicy from '../../components/privacyPolicy';
import { windowFeedback } from '../../components/windows';

const privacyPolicyPage = () => {
    return `
        ${pageTitle({ title: 'Политика конфиденциальности' })}
        ${privacyPolicy()}
        ${footer()}
        ${header()}
        ${menu()}
        ${windowFeedback()}
    `;
}

export default privacyPolicyPage;