import buttonBaseTpl from './base';
import {     
    buttonSpecialAnimated1Tpl,
    buttonSpecialAnimated2Tpl 
} from './special';

const buttonTpl = (initData = {}) => {
    return buttonBaseTpl({...initData});
};

const buttonLinedTpl = (initData = {}) => { 
    return buttonBaseTpl({ ...initData,  modCls: 'ck-button-lined' });
};

const buttonLinedSoftTpl = (initData = {}) => { 
    return buttonBaseTpl({ ...initData,  modCls: 'ck-button-lined-soft' });
};

const buttonRoundedTpl = (initData = {}) => {
    return buttonBaseTpl({...initData, modCls: 'ck-button-rounded' });
};

const buttonLinedRoundedTpl = (initData = {}) => { 
    return buttonBaseTpl({ ...initData,  modCls: 'ck-button-lined ck-button-rounded' });
};

const buttonLinedSoftRoundedTpl = (initData = {}) => { 
    return buttonBaseTpl({ ...initData,  modCls: 'ck-button-lined-soft ck-button-rounded' });
};
export { 
    buttonTpl, 
    buttonLinedTpl, 
    buttonLinedSoftTpl,
    buttonRoundedTpl,
    buttonLinedRoundedTpl,
    buttonLinedSoftRoundedTpl,
    buttonSpecialAnimated1Tpl,
    buttonSpecialAnimated2Tpl
}
