import { dishes } from 'cookery';
import productList from '../../productList';
import './styles';

const { sectionTpl } = dishes;

const production = () => {
    return sectionTpl({ 
        title: { text: 'Наша продукция', size: 'l', color: 'black', style: 'padding: 0 0 40px 0;' }, 
        addCls: 'ck-production', 
        contentHtml: productList()
    });
}

export default production;