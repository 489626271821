import { joggOnObject, findInObject } from '../utils';

class RouteManager { 
    pages = {}
    extraPages = {}
    /** */
    setRootElement = function (rootElement) {
        this.rootElement = rootElement;
    }
    /** */
    setPages = function (pages) {
        this.pages = pages;
    }
    getPages = function () {
        return this.pages;
    }
    getMainPages = function () {
        let pages = {};
        joggOnObject(this.pages, (key, it) => {
            if (!it.extra) { pages[key] = it; }
        })
        return pages
    }
    getExtraPages = function () {
        let pages = {};
        joggOnObject(this.pages, (key, it) => {
            if (it.extra) { pages[key] = it; }
        })
        return pages
    }
    /** */
    setDefaultRoutePage = function (route) {
        joggOnObject(this.pages, (key, data) => {
            data.default = false;
            if (key === route) { data.default = true; } 
        });
    }
    /** */
    getDefaultRoutePageContent = function () { 
        let content = '';
        for (const route in this.pages) {
            this.pages[route].active = false;
            if (this.pages[route].default) {
                this.pages[route].active = true;
                content = this.pages[route].content();
                document.title = this.pages[route].title;
            } 
        }
        return content;
    }
    /** */
    setActiveRoutePage = function (route) {
        joggOnObject(this.pages, (key, data) => {
            data.active = key === route;
        });
    }
    /** */
    getRoutePageContent = function (route = '') {
        route = route.replace('/', ''); 
        if (route === 'home' || !route) {
            return this.getDefaultRoutePageContent();
        } else {
            this.setActiveRoutePage(route);
            document.title = this.pages[route].title;
            return this.pages[route].content();
        }
    }
    /** */
    renderRoutePageContent = function (route) { 
        this.rootElement.innerHTML = this.getRoutePageContent(route);
    }
    /** */
    renderInitialRoutePage = function () {
        const route = location.pathname; 
        this.renderRoutePageContent(route);
    }
    /** */ 
    getRoutePageTitle = (route) => { 
        return this.pages[route].title;
    }
    /** */
    handleBrowserUrlEvents = function () {
        window.onpopstate = function () {
            const route = location.pathname;
            const hash = location.hash;
            this.renderRoutePageContent(route);
        };
    }
    /** */
    getActivePage = function () {
        return findInObject(this.pages, 'active', true);
    }
    /** */
    getCurrentPage = function () {
        return this.getActivePage();
    }
    getActivePageLabel = function () {
        return (this.getActivePage() || {}).label || '';
    }
    /** */
    init = function () {
        this.handleBrowserUrlEvents();
        this.renderInitialRoutePage();
    }
    constructor () {}
}

export default RouteManager;