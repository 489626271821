import { getDefaultHandlers } from './defaults';
import { 
    isArray,
    isObject,
    isString,
    joggOnArray
} from '../utils';

/** */
const syntheticEventNames = {
    render: true,
    destroy: true,
    show: true,
    hide: true,
    linkelement: true,
    formdatachange: true,
    formdatasendingbegin: true,
    formdatasendingcomplete: true,
    fieldvaluechange: true,
    fieldvalueinput: true
};
/** */
const globalEventNames = {
    domupdate: true,
    windowresize: true,
    windowload: true,
    pagescroll: true
}
/** */
const getCmp = ({ ctrl, cmp, id, type }) => {
    if (cmp) { return cmp;  }
    else { 
        const findedCmp = ctrl.getCmp(id);
        return  findedCmp ? findedCmp : ctrl.cookCmp({ type, id });
    }
 }
/** */
const isGlobalEvent = (key) => {
    return globalEventNames[key];
};
/** */
const isSyntheticEvent = (key) => {
    return syntheticEventNames[key];
};
 /** */
const getCombinedHandlers = ({ handler, handlers, defaultHandlers, cmp }) => {
    const hnds = function () {
        let hnds = handlers, hnd = handler;
        hnds = isArray(hnds) ? hnds : isObject(hnds) ? [hnds] : [];
        if (isObject(hnd) && hnd.fn) { hnds.push(hnd); }
        return hnds;
    }();

    return hnds.concat( getDefaultHandlers({ ...defaultHandlers, cmp}) );
}
/** */
const createHandlers = (data = {}) => {
    let { ctrl, createCmpType } = data; 
    const cmp = getCmp({ ctrl, cmp: data.cmp, id: data.id, type: createCmpType });
    const handlers = getCombinedHandlers({ 
        handler: data.handler, 
        handlers: data.handlers, 
        defaultHandlers: data.defaultHandlers,
        cmp 
    });   

    joggOnArray(handlers, (handler) => {
        if (isGlobalEvent(handler.type)) {
            ctrl.eventsManager.addGlobalHandler({ ...handler, ctrl, cmp, id: cmp.id });
         } else if (isSyntheticEvent(handler.type)) {
            ctrl.eventsManager.addSyntheticHandler({ ...handler, ctrl, cmp, id: cmp.id });
        } else {
            ctrl.eventsManager.addHandler({ ...handler, id: cmp.id });
        }
    });
    
    return cmp;
}
/** */
const createHandler = (data = {}) => {
    const { ctrl, type, fn, delay, createCmpType, defaultHandlers } = data;
    const cmp = getCmp({ ...data, ctrl, type: createCmpType });
    
    if (defaultHandlers) {
        createHandlers({ ctrl, cmp, handler: { type, fn, delay }, defaultHandlers });
    } else if (isGlobalEvent(type)) {
         ctrl.eventsManager.addGlobalHandler({ ...data, ctrl, cmp });
     } else if (isSyntheticEvent(type)) {
         ctrl.eventsManager.addSyntheticHandler({ ...data, ctrl, cmp });
    } else {
         ctrl.eventsManager.addHandler({ ...data, cmp, id: cmp.id });
    }
    return cmp;
}

export {
    createHandler,
    createHandlers,
    getDefaultHandlers
}